import { ServerError, ClientError } from "errors";
import { validateCallback, validateToken } from "../../validators";

const API_URL = process.env.REACT_APP_API_URL;

function updateCastFormResponse(sStorage, route, values, endpoint, callback) {
  // TODO: validate values
  if (endpoint === "candidate") {
    validateToken(sStorage.candidate_token);
  } else if (endpoint === "assessor") {
    validateToken(sStorage.token);
  } else if (endpoint === "candidateassessor") {
    validateToken(sStorage.token);
  }
  // import module to validate all values
  validateCallback(callback);
  /*   const {
    fullName,
    gender,
    email,
    organisation,
    writtenResponse,
    stressLevel,
    subscribe,
  } = values; */

  const xhr = new XMLHttpRequest();

  xhr.onload = function () {
    const status = xhr.status;

    if (status >= 500) {
      const message = JSON.parse(xhr.response).error;
      callback(new ServerError(`error ${status}: ${message}`));
    } else if (status >= 400) {
      const message = JSON.parse(xhr.response).error;
      callback(new ClientError(`error ${status}: ${message}`));
    } else if (status === 201) callback(null);
  };

  if (endpoint === "candidate") {
    xhr.open("PATCH", `${API_URL}/forms/candidate/${route}`);
  } else if (endpoint === "assessor") {
    xhr.open("PATCH", `${API_URL}/forms/${route}`);
  } else if (endpoint === "candidateassessor") {
    xhr.open("PATCH", `${API_URL}/forms/${route}`);
  }

  if (endpoint === "candidate") {
    xhr.setRequestHeader("Authorization", `Bearer ${sStorage.candidate_token}`);
  } else if (endpoint === "assessor") {
    xhr.setRequestHeader("Authorization", `Bearer ${sStorage.token}`);
  } else if (endpoint === "candidateassessor") {
    xhr.setRequestHeader("Authorization", `Bearer ${sStorage.token}`);
  }

  xhr.setRequestHeader("Content-type", "application/json");

  xhr.send(
    JSON.stringify({
      values,
    })
  );
}

export default updateCastFormResponse;
