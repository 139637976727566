// ========== Imports ========== //

import "./CreateForm.css";

import { useState, useEffect } from "react";
import { createForm } from "../../../logic";
import Loggito from "../../../utils/Loggito";
import { toast } from "react-toastify";
import { ServerError } from "errors";
import withContext from "../../../utils/withContext";

// ========== Component ========== //

function CreateForm({ context: { refreshTokenCount } }) {
  // ========== Hook consts ========== //

  const [fullName, setFullName] = useState("");
  const [organisation, setOrganisation] = useState("");
  const [typeOfQuestion, setTypeOfQuestion] = useState("");
  const [route, setRoute] = useState("");
  const [state, setState] = useState(0);

  // ========== other consts ========== //

  const logger = new Loggito("CreateForm");

  // ========== useEffects ========== //

  useEffect(() => {
    logger.info("componentDidMount");
    // TODO: generate a better random and unique ID here to pass as the route
    setRoute(
      Date.now().toString() + Math.floor(Math.random() * 1000000).toString()
    );
  }, []);

  useEffect(() => {
    console.log(typeOfQuestion);
  }, [typeOfQuestion, organisation]);

  // ========== Function to add/show CreateForm input fields ========== //

  const changeState = () => {
    if (state === 0) {
      setState(1);
    } else if (state === 1) {
      setState(0);
    }
  };

  const handleChange = (input, value) => {
    if (input === "fullName") setFullName(value);
    else if (input === "organisation") setOrganisation(value);
    else if (input === "candidate" || "interviewer") setTypeOfQuestion(value);
    return;
  };

  // ========== Functions ========== //

  const handleCreateEvent = () => {
    try {
      // refreshTokenHandler();
      createForm(
        sessionStorage.token,
        fullName,
        organisation,
        route,
        typeOfQuestion,
        function (error) {
          if (error) {
            if (error instanceof ServerError) {
              toast.error(error.message);
              logger.error(error.message);
            } else {
              toast.warn(error.message);
              logger.warn(error.message);
              // TODO: check this refresh_token implementation
              if (error.message === "session timed out, please log in again") {
                delete sessionStorage.token;
                refreshTokenCount("stop");
                window.location.reload(false);
              }
            }
            return;
          }
          // TODO: navigate to editNews page
          toast.success("form created successfully");
          window.location.reload(false);
        }
      );
    } catch (error) {
      logger.warn(error.message);
      toast.warn(error.message);
    }
  };

  const introduceAndClose = () => {
    handleCreateEvent(fullName, organisation);
    changeState();
  };

  // ========== jsx ========== //

  return (
    <div className="create-form">
      {state === 0 ? (
        <button className="cta cta--accent" onClick={changeState}>
          Create form
        </button>
      ) : null}
      {state === 1 ? (
        <div className="p--medium p--bold">
          <div>
            <label htmlFor="fullName">Full name of candidate:</label>

            <input
              name="fullName"
              type="text"
              // value={fullName}
              onChange={(e) => handleChange("fullName", e.target.value)}
              className=""
              placeholder="This can be modified later..."
            />
          </div>
          <div>
            <label htmlFor="organisation">Organisation:</label>

            <input
              name="organisation"
              type="text"
              // value={organisation}
              onChange={(e) => handleChange("organisation", e.target.value)}
              className=""
              placeholder="This can be modified later..."
            />
          </div>
          <div>
            <label htmlFor={"candidate"} className="p--sm">
              Candidate
            </label>
            <input
              type="radio"
              id="candidate"
              name="detailsTypeOfResponse"
              value="candidate"
              onChange={(e) => handleChange("candidate", e.target.value)}
            />
            <label htmlFor={"interviewer"} className="p--sm">
              Interviewer
            </label>
            <input
              type="radio"
              id="interviewer"
              name="detailsTypeOfResponse"
              value="interviewer"
              onChange={(e) => handleChange("interviewer", e.target.value)}
            />
          </div>
          <div>
            <button className="cta cta--primary" onClick={introduceAndClose}>
              Create
            </button>
            <button className="cta cta--outline" onClick={changeState}>
              Cancel
            </button>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default withContext(CreateForm);
