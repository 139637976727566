// ========== imports ========== //

import React, { useRef, useState, useEffect, useCallback } from "react";

import { useParams, useNavigate } from "react-router-dom";

import useAnalyticsPageViewSenderGA4 from "../../../../utils/useAnalyticsPageViewSenderGA4";

import Loggito from "../../../../utils/Loggito";
import withContext from "../../../../utils/withContext";

import { updateCastFormResponse, getFormResponse } from "../../../../logic";

import { toast } from "react-toastify";

import { ServerError } from "errors";

import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";

// ========== Page ========== //

function AssessorNav({}) {
  // ========== Hook consts ========== //
  const [formDataBeingSent, setFormDataBeingSent] = useState(true);

  const navigate = useNavigate();

  // ========== other consts ========== //

  const logger = new Loggito("Form Assessor Nav");

  // ========== useEffects ========== //

  // ========== jsx ========== //

  const questionsArray = ["A1a", "A1b", "A2", "A3", "A4", "A5", "A6"];

  return (
    <div className="assessor-nav-panel p--m">
      {questionsArray.map((question) => {
        return (
          <a href={`#${question}_link`}>
            <div className="assessor-nav-button assessor-nav-button__error">
              {question}
            </div>
            {/* <div className={`assessor-nav-button ${A1a.errors && "assessor-nav-button__error"} ${A1a.value && "assessor-nav-button__answered"}`}>A1a</div> */}
          </a>
        );
      })}
    </div>
  );
}

export default withContext(AssessorNav);
