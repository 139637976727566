// ========== imports ========== //

import React, { useRef, useState, useEffect, useCallback } from "react";

import { useParams, useLocation } from "react-router-dom";

import useAnalyticsPageViewSenderGA4 from "../../../../utils/useAnalyticsPageViewSenderGA4";

import Loggito from "../../../../utils/Loggito";
import withContext from "../../../../utils/withContext";

import { updateCastFormResponse } from "../../../../logic";

import { toast } from "react-toastify";

import { IoWarningOutline } from "react-icons/io5";

import { ServerError } from "errors";

import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";

import {
  schemaFormSectionC,
  validateSectionCAssessor,
  validateSectionCCandidate,
} from "../../../../validators";

import {
  QuestionAssessmentContainer,
  QuestionMcq14Strongly,
} from "./FormComponents";

// ========== Page ========== //

function SectionC({ environment, onApproveSection, existingValues }) {
  // ========== Hook consts ========== //
  const [formDataBeingSent, setFormDataBeingSent] = useState(true);

  const [errorsAssessor, setErrorsAssessor] = useState({});
  const [errorsCandidate, setErrorsCandidate] = useState({});

  const form = useRef();
  const location = useLocation();

  const gaPageViewSender = useAnalyticsPageViewSenderGA4();
  useEffect(() => {
    gaPageViewSender("CAST Form Dev");
  }, []);

  const {
    register: registerC,
    handleSubmit: handleSubmitC,
    reset,
    formState: { errors },
    getValues: getValuesC,
    watch,
    setValue,
  } = useForm({
    resolver: joiResolver(schemaFormSectionC),
    reValidateMode: "onChange",
  });

  // THIS WORKS - VALUES MUST BE DEALT WITH SEPARATELY FOR SETVALUE

  useEffect(() => {
    if (Object.entries(existingValues).length > 0) {
      for (const [key, value] of Object.entries(existingValues)) {
        /*  if (key[0] === "C" && value) {
            setValue(key, value.response, {});
            // TODO: investigate the benefit of these attributes
            // shouldValidate: true,
            // shouldDirty: true,
          } */
        if (key[0] === "C") {
          if (value.response) setValue(key, value.response, {});
          if (value.score) setValue(key + "_score", value.score, {});
          if (value.comment) setValue(key + "_comment", value.comment, {});
        }
      }
      setFormDataBeingSent(false);
    }
  }, [existingValues]);

  const [userText, setUserText] = useState("");

  const handleUserKeyPress = useCallback((event) => {
    const { key, keyCode } = event;

    if (keyCode === 32 || (keyCode >= 65 && keyCode <= 90)) {
      setUserText(key);
    }
  }, []);

  useEffect(() => {
    const delayFn = setTimeout(() => saveFormData(null), 3000);
    return () => clearTimeout(delayFn);
  }, [userText]);

  useEffect(() => {
    // advised to create a constant variable as the form.current is likely to change before cleanup
    const formReferenced = form.current;
    formReferenced.addEventListener("keydown", handleUserKeyPress);

    // cleanup on dismount component
    return () => {
      formReferenced.removeEventListener("keydown", handleUserKeyPress);
    };
  }, [handleUserKeyPress]);

  // END SUBMIT AFTER TYPING

  const params = useParams();
  let route = params.route;

  const saveFormData = async (approved) => {
    const questionNumbersArray = [
      "C1",
      "C2",
      "C3",
      "C4",
      "C5",
      "C6",
      "C7",
      "C8",
      "C9",
      "C10",
      "C11",
      "C12",
    ];

    const values = getValuesC();

    let groupedValuesObject = {};

    questionNumbersArray.forEach((questionNumber) => {
      const scoreKey = questionNumber + "_score";
      const commentKey = questionNumber + "_comment";
      groupedValuesObject = {
        ...groupedValuesObject,
        [questionNumber]: {
          response: values[questionNumber],
          score: values[scoreKey],
          comment: values[commentKey],
        },
      };
    });

    console.log(values);

    if (approved === "approved")
      groupedValuesObject.detailsFormSectionApproved = "sectionC";

    let endpoint;

    if (location.pathname.slice(1).slice(0, 5) === "admin") {
      endpoint = "assessor";
    } else if (
      location.pathname.slice(1).slice(0, 5) === "admin" &&
      environment === "candidate"
    ) {
      endpoint = "candidateassessor";
    } else endpoint = "candidate";

    setFormDataBeingSent(true);
    try {
      updateCastFormResponse(
        sessionStorage,
        route,
        groupedValuesObject,
        endpoint,
        function (error) {
          if (error) {
            if (error instanceof ServerError) {
              toast.error(error.message);
              logger.error(error.message);
            } else {
              toast.warn(error.message);
              logger.warn(error.message);
            }
            return;
          }
          toast.success("Your answers have been saved");
        }
      );
    } catch (error) {
      logger.warn(error.message);
      toast.warn(error.message);
    }

    setFormDataBeingSent(false);
  };

  // THIS SEPARATE VALIDATION HANDLES THE FINAL REVIEW TO CLOSE THE SECTION AND MOVE ONTO THE NEXT

  const handleValidateSectionAssessor = () => {
    const formValues = getValuesC();
    const errors = validateSectionCAssessor(formValues);
    if (errors) {
      setErrorsAssessor(errors);
      return;
    } else {
      // HERE THE DATA SHOULD BE SAVED ONE FINAL TIME AND THEN THE FOLLWING STEPS, CLOSING THE SECTION AND MOVING ONTO THE FOLLOWING SECTION...
      setErrorsAssessor({});
      saveFormData(null);
      alert("Form Review OK and final data submitted");
      if (environment === "candidate") onApproveSection("sectionC");
      return;
    }
  };

  const handleValidateSectionCandidate = () => {
    const formValues = getValuesC();
    const errors = validateSectionCCandidate(formValues);
    if (errors) {
      setErrorsCandidate(errors);
      return;
    } else {
      // HERE THE DATA SHOULD BE SAVED ONE FINAL TIME AND THEN THE FOLLWING STEPS, CLOSING THE SECTION AND MOVING ONTO THE FOLLOWING SECTION...
      setErrorsCandidate({});
      saveFormData("approved");
      alert("Form Review OK and final data submitted");
      onApproveSection("sectionC");
      return;
    }
  };

  // ========== other consts ========== //

  const logger = new Loggito("Form section C");

  // ========== useEffects ========== //

  // ========== jsx ========== //

  return (
    <form
      ref={form}
      // onSubmit={handleSubmitC((data) => saveFormData())}
      onSubmit={(event) => {
        event.preventDefault();
        handleSubmitC((data) => saveFormData());
      }}
      className="cast-form"
      noValidate={true}
    >
      {(Object.keys(errorsAssessor).length !== 0 &&
        errorsAssessor.constructor === Object) ||
      (Object.keys(errorsCandidate).length !== 0 &&
        errorsCandidate.constructor === Object) ? (
        <div className="error-block">
          <IoWarningOutline className="icon" />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <p className="p--m">
            There are questions that require your attention
          </p>
        </div>
      ) : null}
      <div className="cast-form__title-block">
        <h2 className="h2">Section C</h2>
        <p className="p--m">
          Score these statements from 0-3 based on how much you agree or
          disagree with them. <br />
          <br />
          0 strongly disagree <br />1 disagree, <br />2 agree <br />3 strongly
          agree
          <br />
          &nbsp;
        </p>
      </div>
      {/*  {errors
        ? Object.entries(errors).map((error) => {
            return <li key={error}>{error}</li>;
          })
        : null} */}
      <QuestionMcq14Strongly
        onSubmit={handleSubmitC((data) => saveFormData())}
        question={"C1"}
        questionText={
          "Consider this metaphor - A leopard will never change its spots."
        }
        register={registerC}
        qErrors={errors.C1}
        qErrorsAssessor={errorsAssessor.C1}
        qErrorsCandidate={errorsCandidate.C1}
        reversed={true}
      />
      {environment === "assessor" ? (
        <QuestionAssessmentContainer
          onSubmit={handleSubmitC((data) => saveFormData())}
          question={"C1"}
          watch={watch}
          scoreType={"Automatically scored"}
          scoreExplanation={""}
          register={registerC}
          qErrorsAssessor={{
            score: errorsAssessor.C1_score,
            comment: errorsAssessor.C1_comment,
          }}
        />
      ) : null}
      <QuestionMcq14Strongly
        onSubmit={handleSubmitC((data) => saveFormData())}
        question={"C2"}
        questionText={
          "Your thoughts can determine whether or not you can do something."
        }
        register={registerC}
        qErrors={errors.C2}
        qErrorsAssessor={errorsAssessor.C2}
        qErrorsCandidate={errorsCandidate.C2}
      />
      {environment === "assessor" ? (
        <QuestionAssessmentContainer
          onSubmit={handleSubmitC((data) => saveFormData())}
          question={"C2"}
          watch={watch}
          scoreType={"Automatically scored"}
          scoreExplanation={""}
          register={registerC}
          qErrorsAssessor={{
            score: errorsAssessor.C2_score,
            comment: errorsAssessor.C2_comment,
          }}
        />
      ) : null}
      <QuestionMcq14Strongly
        onSubmit={handleSubmitC((data) => saveFormData())}
        question={"C3"}
        questionText={
          "You can always substantially change how capable you are. "
        }
        register={registerC}
        qErrors={errors.C3}
        qErrorsAssessor={errorsAssessor.C3}
        qErrorsCandidate={errorsCandidate.C3}
      />
      {environment === "assessor" ? (
        <QuestionAssessmentContainer
          onSubmit={handleSubmitC((data) => saveFormData())}
          question={"C3"}
          watch={watch}
          scoreType={"Automatically scored"}
          scoreExplanation={""}
          register={registerC}
          qErrorsAssessor={{
            score: errorsAssessor.C3_score,
            comment: errorsAssessor.C3_comment,
          }}
        />
      ) : null}
      <QuestionMcq14Strongly
        onSubmit={handleSubmitC((data) => saveFormData())}
        question={"C4"}
        questionText={"People who change their minds are unreliable. "}
        register={registerC}
        qErrors={errors.C4}
        qErrorsAssessor={errorsAssessor.C4}
        qErrorsCandidate={errorsCandidate.C4}
        reversed={true}
      />
      {environment === "assessor" ? (
        <QuestionAssessmentContainer
          onSubmit={handleSubmitC((data) => saveFormData())}
          question={"C4"}
          watch={watch}
          scoreType={"Automatically scored"}
          scoreExplanation={""}
          register={registerC}
          qErrorsAssessor={{
            score: errorsAssessor.C4_score,
            comment: errorsAssessor.C4_comment,
          }}
        />
      ) : null}
      <QuestionMcq14Strongly
        onSubmit={handleSubmitC((data) => saveFormData())}
        question={"C5"}
        questionText={
          "I have overcome a difficult situation and conquered an important challenge."
        }
        register={registerC}
        qErrors={errors.C5}
        qErrorsAssessor={errorsAssessor.C5}
        qErrorsCandidate={errorsCandidate.C5}
      />
      {environment === "assessor" ? (
        <QuestionAssessmentContainer
          onSubmit={handleSubmitC((data) => saveFormData())}
          question={"C5"}
          watch={watch}
          scoreType={"Automatically scored"}
          scoreExplanation={""}
          register={registerC}
          qErrorsAssessor={{
            score: errorsAssessor.C5_score,
            comment: errorsAssessor.C5_comment,
          }}
        />
      ) : null}
      <QuestionMcq14Strongly
        onSubmit={handleSubmitC((data) => saveFormData())}
        question={"C6"}
        questionText={"I always finish whatever I begin. "}
        register={registerC}
        qErrors={errors.C6}
        qErrorsAssessor={errorsAssessor.C6}
        qErrorsCandidate={errorsCandidate.C6}
      />
      {environment === "assessor" ? (
        <QuestionAssessmentContainer
          onSubmit={handleSubmitC((data) => saveFormData())}
          question={"C6"}
          watch={watch}
          scoreType={"Automatically scored"}
          scoreExplanation={""}
          register={registerC}
          qErrorsAssessor={{
            score: errorsAssessor.C6_score,
            comment: errorsAssessor.C6_comment,
          }}
        />
      ) : null}
      <QuestionMcq14Strongly
        onSubmit={handleSubmitC((data) => saveFormData())}
        question={"C7"}
        questionText={
          '"Success is never final; failure is never fatal: It is the courage to continue that counts”. Ask for example from own life.'
        }
        register={registerC}
        qErrors={errors.C7}
        qErrorsAssessor={errorsAssessor.C7}
        qErrorsCandidate={errorsCandidate.C7}
      />
      {environment === "assessor" ? (
        <QuestionAssessmentContainer
          onSubmit={handleSubmitC((data) => saveFormData())}
          question={"C7"}
          watch={watch}
          scoreType={"Automatically scored"}
          scoreExplanation={""}
          register={registerC}
          qErrorsAssessor={{
            score: errorsAssessor.C7_score,
            comment: errorsAssessor.C7_comment,
          }}
        />
      ) : null}
      <QuestionMcq14Strongly
        onSubmit={handleSubmitC((data) => saveFormData())}
        question={"C8"}
        questionText={"It is better to praise talent than effort. "}
        register={registerC}
        qErrors={errors.C8}
        qErrorsAssessor={errorsAssessor.C8}
        qErrorsCandidate={errorsCandidate.C8}
        reversed={true}
      />
      {environment === "assessor" ? (
        <QuestionAssessmentContainer
          onSubmit={handleSubmitC((data) => saveFormData())}
          question={"C8"}
          watch={watch}
          scoreType={"Automatically scored"}
          scoreExplanation={""}
          register={registerC}
          qErrorsAssessor={{
            score: errorsAssessor.C8_score,
            comment: errorsAssessor.C8_comment,
          }}
        />
      ) : null}
      <QuestionMcq14Strongly
        onSubmit={handleSubmitC((data) => saveFormData())}
        question={"C9"}
        questionText={
          "A positive role model or mentor can always help someone change. "
        }
        register={registerC}
        qErrors={errors.C9}
        qErrorsAssessor={errorsAssessor.C9}
        qErrorsCandidate={errorsCandidate.C9}
      />
      {environment === "assessor" ? (
        <QuestionAssessmentContainer
          onSubmit={handleSubmitC((data) => saveFormData())}
          question={"C9"}
          watch={watch}
          scoreType={"Automatically scored"}
          scoreExplanation={""}
          register={registerC}
          qErrorsAssessor={{
            score: errorsAssessor.C9_score,
            comment: errorsAssessor.C9_comment,
          }}
        />
      ) : null}
      <QuestionMcq14Strongly
        onSubmit={handleSubmitC((data) => saveFormData())}
        question={"C10"}
        questionText={
          "Cognitive and emotional intelligence are something very basic about you that you can’t change very much."
        }
        register={registerC}
        qErrors={errors.C10}
        qErrorsAssessor={errorsAssessor.C10}
        qErrorsCandidate={errorsCandidate.C10}
        reversed={true}
      />
      {environment === "assessor" ? (
        <QuestionAssessmentContainer
          onSubmit={handleSubmitC((data) => saveFormData())}
          question={"C10"}
          watch={watch}
          scoreType={"Automatically scored"}
          scoreExplanation={""}
          register={registerC}
          qErrorsAssessor={{
            score: errorsAssessor.C10_score,
            comment: errorsAssessor.C10_comment,
          }}
        />
      ) : null}
      <QuestionMcq14Strongly
        onSubmit={handleSubmitC((data) => saveFormData())}
        question={"C11"}
        questionText={
          "If someone told you can’t do something, it probably means that you can’t."
        }
        register={registerC}
        qErrors={errors.C11}
        qErrorsAssessor={errorsAssessor.C11}
        qErrorsCandidate={errorsCandidate.C11}
        reversed={true}
      />
      {environment === "assessor" ? (
        <QuestionAssessmentContainer
          onSubmit={handleSubmitC((data) => saveFormData())}
          question={"C11"}
          watch={watch}
          scoreType={"Automatically scored"}
          scoreExplanation={""}
          register={registerC}
          qErrorsAssessor={{
            score: errorsAssessor.C11_score,
            comment: errorsAssessor.C11_comment,
          }}
        />
      ) : null}
      <QuestionMcq14Strongly
        onSubmit={handleSubmitC((data) => saveFormData())}
        question={"C12"}
        questionText={"Failure is always an opportunity"}
        register={registerC}
        qErrors={errors.C12}
        qErrorsAssessor={errorsAssessor.C12}
        qErrorsCandidate={errorsCandidate.C12}
      />
      {environment === "assessor" ? (
        <QuestionAssessmentContainer
          onSubmit={handleSubmitC((data) => saveFormData())}
          question={"C12"}
          watch={watch}
          scoreType={"Automatically scored"}
          scoreExplanation={""}
          register={registerC}
          qErrorsAssessor={{
            score: errorsAssessor.C12_score,
            comment: errorsAssessor.C12_comment,
          }}
        />
      ) : null}

      {formDataBeingSent ? (
        <button type="button" className="cta cta--accent--clicked">
          Review
        </button>
      ) : null}
      {environment === "assessor" && !formDataBeingSent ? (
        <button
          type="button"
          className="cta cta--accent"
          onClick={handleValidateSectionAssessor}
        >
          Review
        </button>
      ) : null}
      {environment === "candidate" && !formDataBeingSent ? (
        <button
          type="button"
          className="cta cta--accent"
          onClick={handleValidateSectionCandidate}
        >
          Review
        </button>
      ) : null}
    </form>
  );
}

export default withContext(SectionC);
