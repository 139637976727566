// ========== imports ========== //

import React, { useRef, useState, useEffect, useCallback } from "react";

import { useParams, useLocation } from "react-router-dom";

import useAnalyticsPageViewSenderGA4 from "../../../../utils/useAnalyticsPageViewSenderGA4";

import Loggito from "../../../../utils/Loggito";
import withContext from "../../../../utils/withContext";

import { updateCastFormResponse } from "../../../../logic";

import { toast } from "react-toastify";

import { IoWarningOutline } from "react-icons/io5";

import { ServerError } from "errors";

import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";

import {
  schemaFormSection1,
  validateSection1Assessor,
  validateSection1Candidate,
} from "../../../../validators";

import {
  QuestionMcqListHorizontal,
  QuestionShortText,
  QuestionDateAssessed,
} from "./FormComponents";

// ========== Page ========== //

function Section1({ environment, onApproveSection, existingValues }) {
  // ========== Hook consts ========== //
  // const [formDataBeingSent, setFormDataBeingSent] = useState(false);
  const [formDataBeingSent, setFormDataBeingSent] = useState(true);

  const [errorsAssessor, setErrorsAssessor] = useState({});
  const [errorsCandidate, setErrorsCandidate] = useState({});

  const form = useRef();

  const gaPageViewSender = useAnalyticsPageViewSenderGA4();

  useEffect(() => {
    gaPageViewSender("CAST Form Dev");
  }, []);

  const params = useParams();
  let route = params.route;

  const location = useLocation();

  const {
    register: registerSection1,
    handleSubmit: handleSubmitSection1,
    reset,
    formState: { errors },
    getValues: getValuesSection1,
    watch,
    setValue,
  } = useForm({
    resolver: joiResolver(schemaFormSection1),
    reValidateMode: "onChange",
  });

  // THIS WORKS - VALUES MUST BE DEALT WITH SEPARATELY FOR SETVALUE

  useEffect(() => {
    if (Object.entries(existingValues).length > 0) {
      const formIdsArray = [
        "detailsFullName",
        "detailsCandidateEmail",
        "detailsOrganisation",
        "detailsRole",
        "detailsGender",
        "detailsAssessor",
        "detailsDateAssessed",
      ];
      for (const [key, value] of Object.entries(existingValues)) {
        if (
          key.slice(0, 7) === "details" &&
          value &&
          formIdsArray.includes(key)
        ) {
          setValue(key, value, {});
        }
      }
      setFormDataBeingSent(false);
    }
  }, [existingValues]);

  // START SUBMIT AFTER TYPING - THIS SUBMITS THE USER'S RESPONSE AFTER THEY STOP TYPING

  const [userText, setUserText] = useState("");

  const handleUserKeyPress = useCallback((event) => {
    const { key, keyCode } = event;

    if (keyCode === 32 || (keyCode >= 65 && keyCode <= 90)) {
      setUserText(key);
    }
  }, []);

  useEffect(() => {
    const delayFn = setTimeout(() => saveFormData(null), 3000);
    return () => clearTimeout(delayFn);
  }, [userText]);

  useEffect(() => {
    // advised to create a constant variable as the form.current is likely to change before cleanup
    const formReferenced = form.current;
    formReferenced.addEventListener("keydown", handleUserKeyPress);

    // cleanup on dismount component
    return () => {
      formReferenced.removeEventListener("keydown", handleUserKeyPress);
    };
  }, [handleUserKeyPress]);

  // END SUBMIT AFTER TYPING

  const saveFormData = async (approved) => {
    const values = getValuesSection1();

    if (approved === "approved") values.detailsFormSectionApproved = "section1";

    console.log(values);

    let endpoint;

    if (location.pathname.slice(1).slice(0, 5) === "admin") {
      endpoint = "assessor";
    } else if (
      location.pathname.slice(1).slice(0, 5) === "admin" &&
      environment === "candidate"
    ) {
      endpoint = "candidateassessor";
    } else endpoint = "candidate";

    setFormDataBeingSent(true);
    try {
      updateCastFormResponse(
        sessionStorage,
        route,
        values,
        endpoint,
        function (error) {
          if (error) {
            if (error instanceof ServerError) {
              toast.error(error.message);
              logger.error(error.message);
            } else {
              toast.warn(error.message);
              logger.warn(error.message);
            }
            return;
          }
          toast.success("Your answers have been saved");
        }
      );
    } catch (error) {
      logger.warn(error.message);
      toast.warn(error.message);
    }

    setFormDataBeingSent(false);
  };

  // THIS SEPARATE VALIDATION HANDLES THE FINAL REVIEW TO CLOSE THE SECTION AND MOVE ONTO THE NEXT

  const handleValidateSectionAssessor = () => {
    const formValues = getValuesSection1();
    const errors = validateSection1Assessor(formValues);
    if (errors) {
      setErrorsAssessor(errors);
      return;
    } else {
      // HERE THE DATA SHOULD BE SAVED ONE FINAL TIME AND THEN THE FOLLWING STEPS, CLOSING THE SECTION AND MOVING ONTO THE FOLLOWING SECTION...
      setErrorsAssessor({});
      saveFormData(null);
      alert("Form Review OK and final data submitted");
      // if (environment === "candidate") onApproveSection("section1");
      return;
    }
  };

  const handleValidateSectionCandidate = () => {
    const formValues = getValuesSection1();
    const errors = validateSection1Candidate(formValues);
    if (errors) {
      setErrorsCandidate(errors);
      return;
    } else {
      // HERE THE DATA SHOULD BE SAVED ONE FINAL TIME AND THEN THE FOLLWING STEPS, CLOSING THE SECTION AND MOVING ONTO THE FOLLOWING SECTION...
      setErrorsCandidate({});
      saveFormData("approved");
      alert("Form Review OK and final data submitted");
      onApproveSection("section1");
      return;
    }
  };

  // ========== other consts ========== //

  const logger = new Loggito("Form section A");

  // ========== useEffects ========== //

  // ========== jsx ========== //

  return (
    <form
      ref={form}
      // onSubmit={handleSubmitSection1((data) => saveFormData())}
      onSubmit={(event) => {
        event.preventDefault();
        handleSubmitSection1((data) => saveFormData());
      }}
      className="cast-form"
      noValidate={true}
    >
      {/* TODO: this is set up for Assessor errors */}
      {/* {Object.keys(errorsAssessor).length !== 0 &&
      errorsAssessor.constructor === Object ? (
        <div className="error-block">
          <IoWarningOutline className="icon" />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <p className="p--m">There are questions that require your attention</p>
        </div>
      ) : null} */}
      {(Object.keys(errorsAssessor).length !== 0 &&
        errorsAssessor.constructor === Object) ||
      (Object.keys(errorsCandidate).length !== 0 &&
        errorsCandidate.constructor === Object) ? (
        <div className="error-block">
          <IoWarningOutline className="icon" />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <p className="p--m">
            There are questions that require your attention
          </p>
        </div>
      ) : null}
      <div className="cast-form__title-block">
        <h2 className="h2">Section 1</h2>
        <p className="p--m">
          Please complete the details below and save your progress once you have
          finished and are ready to move to the next section.
          <br />
          &nbsp;
        </p>
      </div>
      <QuestionShortText
        question={"detailsFullName"}
        questionText={"* Full name:"}
        register={registerSection1}
        qErrors={errors.detailsFullName}
        section={"1"}
        qErrorsAssessor={errorsAssessor.detailsFullName}
        qErrorsCandidate={errorsCandidate.detailsFullName}
      />
      <QuestionShortText
        question={"detailsCandidateEmail"}
        questionText={"* Email:"}
        register={registerSection1}
        qErrors={errors.detailsCandidateEmail}
        section={"1"}
        qErrorsAssessor={errorsAssessor.detailsCandidateEmail}
        qErrorsCandidate={errorsCandidate.detailsCandidateEmail}
      />
      <QuestionShortText
        question={"detailsOrganisation"}
        questionText={"* Company/Organisation:"}
        register={registerSection1}
        qErrors={errors.detailsOrganisation}
        section={"1"}
        qErrorsAssessor={errorsAssessor.detailsOrganisation}
        qErrorsCandidate={errorsCandidate.detailsOrganisation}
      />
      <QuestionShortText
        question={"detailsRole"}
        questionText={"* Position/Role:"}
        register={registerSection1}
        qErrors={errors.detailsRole}
        section={"1"}
        qErrorsAssessor={errorsAssessor.detailsRole}
        qErrorsCandidate={errorsCandidate.detailsRole}
      />
      <QuestionMcqListHorizontal
        onSubmit={handleSubmitSection1((data) => saveFormData())}
        question={"detailsGender"}
        questionText={"Please select your gender:"}
        register={registerSection1}
        qErrors={errors.detailsGender}
        labels={["male", "female", "non-binary", "other", "prefer not to say"]}
        section={"1"}
        qErrorsAssessor={errorsAssessor.detailsGender}
        qErrorsCandidate={errorsCandidate.detailsGender}
      />
      {environment === "assessor" ? (
        <QuestionShortText
          question={"detailsAssessor"}
          questionText={"Name of Assessor:"}
          register={registerSection1}
          qErrors={errors.detailsAssessor}
          section={"1"}
          // TODO: check if this works for the short text response, i think it should be fine
          qErrorsAssessor={errorsAssessor.detailsAssessor}
        />
      ) : null}
      {environment === "assessor" ? (
        <QuestionDateAssessed
          onSubmit={handleSubmitSection1((data) => saveFormData())}
          question={"detailsDateAssessed"}
          questionText={`Date Assessed: ${
            existingValues.detailsDateAssessed
              ? "Current Date is " +
                new Date(
                  existingValues.detailsDateAssessed
                ).toLocaleDateString()
              : null
          }`}
          register={registerSection1}
          qErrors={errors.detailsDateAssessed}
          section={"1"}
          qErrorsAssessor={errorsAssessor.detailsDateAssessed}
        />
      ) : null}
      {formDataBeingSent ? (
        <button type="button" className="cta cta--accent--clicked">
          Review
        </button>
      ) : null}
      {environment === "assessor" && !formDataBeingSent ? (
        <button
          type="button"
          className="cta cta--accent"
          onClick={handleValidateSectionAssessor}
        >
          Review
        </button>
      ) : null}
      {environment === "candidate" && !formDataBeingSent ? (
        <button
          type="button"
          className="cta cta--accent"
          onClick={handleValidateSectionCandidate}
        >
          Review
        </button>
      ) : null}
    </form>
  );
}

export default withContext(Section1);
