// ========== imports ========== //

import React, { useRef, useState, useEffect, useCallback } from "react";

import { useParams, useNavigate } from "react-router-dom";

import useAnalyticsPageViewSenderGA4 from "../../../../utils/useAnalyticsPageViewSenderGA4";

import Loggito from "../../../../utils/Loggito";
import withContext from "../../../../utils/withContext";

import { updateCastFormResponse, getFormResponse } from "../../../../logic";

import { toast } from "react-toastify";

import { IoWarningOutline } from "react-icons/io5";

import { ServerError } from "errors";

import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";

import {
  schemaFormSectionF,
  validateSectionFAssessor,
  validateSectionFCandidate,
} from "../../../../validators";

import { QuestionAssessmentContainer, QuestionMcq03 } from "./FormComponents";

// ========== Page ========== //

function SectionF({ environment, onApproveSection, existingValues }) {
  // ========== Hook consts ========== //

  return (
    // change this back to a form to create the feedback survey questions
    <div className="cast-form">
      <div className="cast-form__title-block">
        <h2 className="h2">
          Form Submitted <br />
          &nbsp;
        </h2>
        <p className="p--m">
          Thank you for completing and submitting the form.
          <br />
          <br />
          The report will be created and sent to you by email within X hours.
          <br />
          <br />
          We would appreciate it if you could answer the quixk survey questions
          below, your opinions matter ro us and the feedback will help us
          improve our service.
          <br />
          <br />
          Thank you for your time.
          <br />
          <br />
          &nbsp;
        </p>
      </div>
    </div>
  );
}

export default withContext(SectionF);
