// ========== imports ========== //

import React, { useRef, useState, useEffect, useCallback } from "react";

import { useParams, useLocation } from "react-router-dom";

import useAnalyticsPageViewSenderGA4 from "../../../../utils/useAnalyticsPageViewSenderGA4";

import Loggito from "../../../../utils/Loggito";
import withContext from "../../../../utils/withContext";

import { updateCastFormResponse } from "../../../../logic";

import { toast } from "react-toastify";

import { IoWarningOutline } from "react-icons/io5";

import { ServerError } from "errors";

import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";

import {
  schemaFormSectionF,
  validateSectionFAssessor,
  validateSectionFCandidate,
} from "../../../../validators";

import { QuestionAssessmentContainer, QuestionMcq03 } from "./FormComponents";

// ========== Page ========== //

function SectionF({ environment, onApproveSection, existingValues }) {
  // ========== Hook consts ========== //
  const [formDataBeingSent, setFormDataBeingSent] = useState(true);

  const [errorsAssessor, setErrorsAssessor] = useState({});
  const [errorsCandidate, setErrorsCandidate] = useState({});

  const form = useRef();
  const location = useLocation();

  const gaPageViewSender = useAnalyticsPageViewSenderGA4();
  useEffect(() => {
    gaPageViewSender("CAST Form Dev");
  }, []);

  const {
    register: registerF,
    handleSubmit: handleSubmitF,
    reset,
    formState: { errors },
    getValues: getValuesF,
    watch,
    setValue,
  } = useForm({
    resolver: joiResolver(schemaFormSectionF),
    reValidateMode: "onChange",
  });

  // THIS WORKS - VALUES MUST BE DEALT WITH SEPARATELY FOR SETVALUE

  useEffect(() => {
    if (Object.entries(existingValues).length > 0) {
      for (const [key, value] of Object.entries(existingValues)) {
        /*  if (key[0] === "F" && value) {
            setValue(key, value.response, {});
            // TODO: investigate the benefit of these attributes
            // shouldValidate: true,
            // shouldDirty: true,
          } */
        if (key[0] === "F") {
          if (value.response) setValue(key, value.response, {});
          if (value.score) setValue(key + "_score", value.score, {});
          if (value.comment) setValue(key + "_comment", value.comment, {});
        }
      }
      setFormDataBeingSent(false);
    }
  }, [existingValues]);

  const [userText, setUserText] = useState("");

  const handleUserKeyPress = useCallback((event) => {
    const { key, keyCode } = event;

    if (keyCode === 32 || (keyCode >= 65 && keyCode <= 90)) {
      setUserText(key);
    }
  }, []);

  useEffect(() => {
    const delayFn = setTimeout(() => saveFormData(null), 3000);
    return () => clearTimeout(delayFn);
  }, [userText]);

  useEffect(() => {
    // advised to create a constant variable as the form.current is likely to change before cleanup
    const formReferenced = form.current;
    formReferenced.addEventListener("keydown", handleUserKeyPress);

    // cleanup on dismount component
    return () => {
      formReferenced.removeEventListener("keydown", handleUserKeyPress);
    };
  }, [handleUserKeyPress]);

  // END SUBMIT AFTER TYPING

  const params = useParams();
  let route = params.route;

  const saveFormData = async (approved) => {
    const questionNumbersArray = [
      "F1",
      "F2",
      "F3",
      "F4",
      "F5",
      "F6",
      "F7",
      "F8",
      "F9",
      "F10",
      "F11",
      "F12",
      "F13",
      "F14",
      "F15",
      "F16",
    ];

    const values = getValuesF();

    let groupedValuesObject = {};

    questionNumbersArray.forEach((questionNumber) => {
      const scoreKey = questionNumber + "_score";
      const commentKey = questionNumber + "_comment";
      groupedValuesObject = {
        ...groupedValuesObject,
        [questionNumber]: {
          response: values[questionNumber],
          score: values[scoreKey],
          comment: values[commentKey],
        },
      };
    });

    console.log(values);

    if (approved === "approved")
      groupedValuesObject.detailsFormSectionApproved = "sectionF";

    let endpoint;

    if (location.pathname.slice(1).slice(0, 5) === "admin") {
      endpoint = "assessor";
    } else if (
      location.pathname.slice(1).slice(0, 5) === "admin" &&
      environment === "candidate"
    ) {
      endpoint = "candidateassessor";
    } else endpoint = "candidate";

    setFormDataBeingSent(true);
    try {
      updateCastFormResponse(
        sessionStorage,
        route,
        groupedValuesObject,
        endpoint,
        function (error) {
          if (error) {
            if (error instanceof ServerError) {
              toast.error(error.message);
              logger.error(error.message);
            } else {
              toast.warn(error.message);
              logger.warn(error.message);
            }
            return;
          }
          toast.success("Your answers have been saved");
        }
      );
    } catch (error) {
      logger.warn(error.message);
      toast.warn(error.message);
    }

    setFormDataBeingSent(false);
  };

  // THIS SEPARATE VALIDATION HANDLES THE FINAL REVIEW TO CLOSE THE SECTION AND MOVE ONTO THE NEXT

  const handleValidateSectionAssessor = () => {
    const formValues = getValuesF();
    const errors = validateSectionFAssessor(formValues);
    if (errors) {
      setErrorsAssessor(errors);
      return;
    } else {
      // HERE THE DATA SHOULD BE SAVED ONE FINAL TIME AND THEN THE FOLLWING STEPS, CLOSING THE SECTION AND MOVING ONTO THE FOLLOWING SECTION...
      setErrorsAssessor({});
      saveFormData(null);
      alert("Form Review OK and final data submitted");
      if (environment === "candidate") onApproveSection("sectionF");
      return;
    }
  };

  const handleValidateSectionCandidate = () => {
    const formValues = getValuesF();
    const errors = validateSectionFCandidate(formValues);
    if (errors) {
      setErrorsCandidate(errors);
      return;
    } else {
      // HERE THE DATA SHOULD BE SAVED ONE FINAL TIME AND THEN THE FOLLWING STEPS, CLOSING THE SECTION AND MOVING ONTO THE FOLLOWING SECTION...
      setErrorsCandidate({});
      saveFormData("approved");
      alert("Form Review OK and final data submitted");
      onApproveSection("sectionF");
      return;
    }
  };

  // ========== other consts ========== //

  const logger = new Loggito("Form section F");

  // ========== useEffects ========== //

  // ========== jsx ========== //

  return (
    <form
      ref={form}
      /* onSubmit={handleSubmitF((data) => {
        saveFormData();
      })} */
      onSubmit={(event) => {
        event.preventDefault();
        handleSubmitF((data) => saveFormData());
      }}
      className="cast-form"
      noValidate={true}
    >
      {(Object.keys(errorsAssessor).length !== 0 &&
        errorsAssessor.constructor === Object) ||
      (Object.keys(errorsCandidate).length !== 0 &&
        errorsCandidate.constructor === Object) ? (
        <div className="error-block">
          <IoWarningOutline className="icon" />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <p className="p--m">
            There are questions that require your attention
          </p>
        </div>
      ) : null}
      <div className="cast-form__title-block">
        <h2 className="h2">Section F</h2>
        <p className="p--m">
          In this next section, you will be asked about parenting. This is not
          specifically about your own parents, but rather what you think is the
          most suitable way of parenting. In a general sense, parenting can also
          be thought of in terms of facilitating development, and therefore has
          something in common with leadership, management, and mentoring. You
          will be presented with a set of statements. Please think about how
          much each statement is likely to support or hinder development.
          <br />
          <br />
          Consider carefully how the essence of the statement may help
          development or not.
          <br />
          <br />
          Score these statements from 0-3 according to how well they reflect
          qualities that are likely to support or hinder development.
          <br />
          <br />
          0 = may set back development <br />
          1 = unhelpful for development <br />
          2 = helpful for development <br />
          3 = very helpful for development.
          <br />
          &nbsp;
        </p>
      </div>
      {/*  {errors
        ? Object.entries(errors).map((error) => {
            return <li key={error}>{error}</li>;
          })
        : null} */}

      <QuestionMcq03
        onSubmit={handleSubmitF((data) => saveFormData())}
        question={"F1"}
        questionText={"Great job you are so talented!"}
        register={registerF}
        qErrors={errors.F1}
        qErrorsAssessor={errorsAssessor.F1}
        qErrorsCandidate={errorsCandidate.F1}
        reversed={true}
      />
      {environment === "assessor" ? (
        <QuestionAssessmentContainer
          onSubmit={handleSubmitF((data) => saveFormData())}
          question={"F1"}
          watch={watch}
          scoreType={"Automatically scored"}
          scoreExplanation={""}
          register={registerF}
          category={"undemanding"}
          qErrorsAssessor={{
            score: errorsAssessor.F1_score,
            comment: errorsAssessor.F1_comment,
          }}
        />
      ) : null}
      <QuestionMcq03
        onSubmit={handleSubmitF((data) => saveFormData())}
        question={"F2"}
        questionText={
          "You have improved in your analytical skills since starting here."
        }
        register={registerF}
        qErrors={errors.F2}
        qErrorsAssessor={errorsAssessor.F2}
        qErrorsCandidate={errorsCandidate.F2}
      />
      {environment === "assessor" ? (
        <QuestionAssessmentContainer
          onSubmit={handleSubmitF((data) => saveFormData())}
          question={"F2"}
          watch={watch}
          scoreType={"Automatically scored"}
          scoreExplanation={""}
          register={registerF}
          category={"supportive"}
          qErrorsAssessor={{
            score: errorsAssessor.F2_score,
            comment: errorsAssessor.F2_comment,
          }}
        />
      ) : null}
      <QuestionMcq03
        onSubmit={handleSubmitF((data) => saveFormData())}
        question={"F3"}
        questionText={
          "Well Done! Let's see if we can think about one thing that could have been even better?"
        }
        register={registerF}
        qErrors={errors.F3}
        qErrorsAssessor={errorsAssessor.F3}
        qErrorsCandidate={errorsCandidate.F3}
      />
      {environment === "assessor" ? (
        <QuestionAssessmentContainer
          onSubmit={handleSubmitF((data) => saveFormData())}
          question={"F3"}
          watch={watch}
          scoreType={"Automatically scored"}
          scoreExplanation={""}
          register={registerF}
          category={"demanding"}
          qErrorsAssessor={{
            score: errorsAssessor.F3_score,
            comment: errorsAssessor.F3_comment,
          }}
        />
      ) : null}
      <QuestionMcq03
        onSubmit={handleSubmitF((data) => saveFormData())}
        question={"F4"}
        questionText={"You are someone who loves learning – that is great!"}
        register={registerF}
        qErrors={errors.F4}
        qErrorsAssessor={errorsAssessor.F4}
        qErrorsCandidate={errorsCandidate.F4}
      />
      {environment === "assessor" ? (
        <QuestionAssessmentContainer
          onSubmit={handleSubmitF((data) => saveFormData())}
          question={"F4"}
          watch={watch}
          scoreType={"Automatically scored"}
          scoreExplanation={""}
          register={registerF}
          category={"supportive"}
          qErrorsAssessor={{
            score: errorsAssessor.F4_score,
            comment: errorsAssessor.F4_comment,
          }}
        />
      ) : null}
      <QuestionMcq03
        onSubmit={handleSubmitF((data) => saveFormData())}
        question={"F5"}
        questionText={"I need you to start trying harder."}
        register={registerF}
        qErrors={errors.F5}
        qErrorsAssessor={errorsAssessor.F5}
        qErrorsCandidate={errorsCandidate.F5}
        reversed={true}
      />
      {environment === "assessor" ? (
        <QuestionAssessmentContainer
          onSubmit={handleSubmitF((data) => saveFormData())}
          question={"F5"}
          watch={watch}
          scoreType={"Automatically scored"}
          scoreExplanation={""}
          register={registerF}
          category={"unsupportive"}
          qErrorsAssessor={{
            score: errorsAssessor.F5_score,
            comment: errorsAssessor.F5_comment,
          }}
        />
      ) : null}
      <QuestionMcq03
        onSubmit={handleSubmitF((data) => saveFormData())}
        question={"F6"}
        questionText={"That was hard. Don't feel bad if you can't do it, yet."}
        register={registerF}
        qErrors={errors.F6}
        qErrorsAssessor={errorsAssessor.F6}
        qErrorsCandidate={errorsCandidate.F6}
      />
      {environment === "assessor" ? (
        <QuestionAssessmentContainer
          onSubmit={handleSubmitF((data) => saveFormData())}
          question={"F6"}
          watch={watch}
          scoreType={"Automatically scored"}
          scoreExplanation={""}
          register={registerF}
          category={"supportive"}
          qErrorsAssessor={{
            score: errorsAssessor.F6_score,
            comment: errorsAssessor.F6_comment,
          }}
        />
      ) : null}
      <QuestionMcq03
        onSubmit={handleSubmitF((data) => saveFormData())}
        question={"F7"}
        questionText={"Well, at least you tried!"}
        register={registerF}
        qErrors={errors.F7}
        qErrorsAssessor={errorsAssessor.F7}
        qErrorsCandidate={errorsCandidate.F7}
        reversed={true}
      />
      {environment === "assessor" ? (
        <QuestionAssessmentContainer
          onSubmit={handleSubmitF((data) => saveFormData())}
          question={"F7"}
          watch={watch}
          scoreType={"Automatically scored"}
          scoreExplanation={""}
          register={registerF}
          category={"undemanding"}
          qErrorsAssessor={{
            score: errorsAssessor.F7_score,
            comment: errorsAssessor.F7_comment,
          }}
        />
      ) : null}
      <QuestionMcq03
        onSubmit={handleSubmitF((data) => saveFormData())}
        question={"F8"}
        questionText={"You don't seem to be making very much progress."}
        register={registerF}
        qErrors={errors.F8}
        qErrorsAssessor={errorsAssessor.F8}
        qErrorsCandidate={errorsCandidate.F8}
        reversed={true}
      />
      {environment === "assessor" ? (
        <QuestionAssessmentContainer
          onSubmit={handleSubmitF((data) => saveFormData())}
          question={"F8"}
          watch={watch}
          scoreType={"Automatically scored"}
          scoreExplanation={""}
          register={registerF}
          category={"unsupportive"}
          qErrorsAssessor={{
            score: errorsAssessor.F8_score,
            comment: errorsAssessor.F8_comment,
          }}
        />
      ) : null}
      <QuestionMcq03
        onSubmit={handleSubmitF((data) => saveFormData())}
        question={"F9"}
        questionText={
          "That didn’t work. Let’s talk about how you approached it and what might work better."
        }
        register={registerF}
        qErrors={errors.F9}
        qErrorsAssessor={errorsAssessor.F9}
        qErrorsCandidate={errorsCandidate.F9}
      />
      {environment === "assessor" ? (
        <QuestionAssessmentContainer
          onSubmit={handleSubmitF((data) => saveFormData())}
          question={"F9"}
          watch={watch}
          scoreType={"Automatically scored"}
          scoreExplanation={""}
          register={registerF}
          category={"demanding"}
          qErrorsAssessor={{
            score: errorsAssessor.F9_score,
            comment: errorsAssessor.F9_comment,
          }}
        />
      ) : null}
      <QuestionMcq03
        onSubmit={handleSubmitF((data) => saveFormData())}
        question={"F10"}
        questionText={
          "Maybe this just isn’t your strength. Don’t worry, you have other things to contribute."
        }
        register={registerF}
        qErrors={errors.F10}
        qErrorsAssessor={errorsAssessor.F10}
        qErrorsCandidate={errorsCandidate.F10}
        reversed={true}
      />
      {environment === "assessor" ? (
        <QuestionAssessmentContainer
          onSubmit={handleSubmitF((data) => saveFormData())}
          question={"F10"}
          watch={watch}
          scoreType={"Automatically scored"}
          scoreExplanation={""}
          register={registerF}
          category={"undemanding"}
          qErrorsAssessor={{
            score: errorsAssessor.F10_score,
            comment: errorsAssessor.F10_comment,
          }}
        />
      ) : null}
      <QuestionMcq03
        onSubmit={handleSubmitF((data) => saveFormData())}
        question={"F11"}
        questionText={
          "We have high standards. I’m holding you to them because I know we can reach them together"
        }
        register={registerF}
        qErrors={errors.F11}
        qErrorsAssessor={errorsAssessor.F11}
        qErrorsCandidate={errorsCandidate.F11}
      />
      {environment === "assessor" ? (
        <QuestionAssessmentContainer
          onSubmit={handleSubmitF((data) => saveFormData())}
          question={"F11"}
          watch={watch}
          scoreType={"Automatically scored"}
          scoreExplanation={""}
          register={registerF}
          category={"demanding"}
          qErrorsAssessor={{
            score: errorsAssessor.F11_score,
            comment: errorsAssessor.F11_comment,
          }}
        />
      ) : null}
      <QuestionMcq03
        onSubmit={handleSubmitF((data) => saveFormData())}
        question={"F12"}
        questionText={"This is hard don't feel bad if you can't do it."}
        register={registerF}
        qErrors={errors.F12}
        qErrorsAssessor={errorsAssessor.F12}
        qErrorsCandidate={errorsCandidate.F12}
        reversed={true}
      />
      {environment === "assessor" ? (
        <QuestionAssessmentContainer
          onSubmit={handleSubmitF((data) => saveFormData())}
          question={"F12"}
          watch={watch}
          scoreType={"Automatically scored"}
          scoreExplanation={""}
          register={registerF}
          category={"undemanding"}
          qErrorsAssessor={{
            score: errorsAssessor.F12_score,
            comment: errorsAssessor.F12_comment,
          }}
        />
      ) : null}
      <QuestionMcq03
        onSubmit={handleSubmitF((data) => saveFormData())}
        question={"F13"}
        questionText={
          "That didn't work out very well. I don't think it's one of your strengths."
        }
        register={registerF}
        qErrors={errors.F13}
        qErrorsAssessor={errorsAssessor.F13}
        qErrorsCandidate={errorsCandidate.F13}
        reversed={true}
      />
      {environment === "assessor" ? (
        <QuestionAssessmentContainer
          onSubmit={handleSubmitF((data) => saveFormData())}
          question={"F13"}
          watch={watch}
          scoreType={"Automatically scored"}
          scoreExplanation={""}
          register={registerF}
          category={"unsupportive"}
          qErrorsAssessor={{
            score: errorsAssessor.F13_score,
            comment: errorsAssessor.F13_comment,
          }}
        />
      ) : null}
      <QuestionMcq03
        onSubmit={handleSubmitF((data) => saveFormData())}
        question={"F14"}
        questionText={"The amount of effort you put in here is good."}
        register={registerF}
        qErrors={errors.F14}
        qErrorsAssessor={errorsAssessor.F14}
        qErrorsCandidate={errorsCandidate.F14}
      />
      {environment === "assessor" ? (
        <QuestionAssessmentContainer
          onSubmit={handleSubmitF((data) => saveFormData())}
          question={"F14"}
          watch={watch}
          scoreType={"Automatically scored"}
          scoreExplanation={""}
          register={registerF}
          category={"supportive"}
          qErrorsAssessor={{
            score: errorsAssessor.F14_score,
            comment: errorsAssessor.F14_comment,
          }}
        />
      ) : null}
      <QuestionMcq03
        onSubmit={handleSubmitF((data) => saveFormData())}
        question={"F15"}
        questionText={
          "I believe you are very capable and ready for more responsibility."
        }
        register={registerF}
        qErrors={errors.F15}
        qErrorsAssessor={errorsAssessor.F15}
        qErrorsCandidate={errorsCandidate.F15}
      />
      {environment === "assessor" ? (
        <QuestionAssessmentContainer
          onSubmit={handleSubmitF((data) => saveFormData())}
          question={"F15"}
          watch={watch}
          scoreType={"Automatically scored"}
          scoreExplanation={""}
          register={registerF}
          category={"demanding"}
          qErrorsAssessor={{
            score: errorsAssessor.F15_score,
            comment: errorsAssessor.F15_comment,
          }}
        />
      ) : null}
      <QuestionMcq03
        onSubmit={handleSubmitF((data) => saveFormData())}
        question={"F16"}
        questionText={"I don't think you have enough experience for this task."}
        register={registerF}
        qErrors={errors.F16}
        qErrorsAssessor={errorsAssessor.F16}
        qErrorsCandidate={errorsCandidate.F16}
        reversed={true}
      />
      {environment === "assessor" ? (
        <QuestionAssessmentContainer
          onSubmit={handleSubmitF((data) => saveFormData())}
          question={"F16"}
          watch={watch}
          scoreType={"Automatically scored"}
          scoreExplanation={""}
          register={registerF}
          category={"unsupportive"}
          qErrorsAssessor={{
            score: errorsAssessor.F16_score,
            comment: errorsAssessor.F16_comment,
          }}
        />
      ) : null}

      {formDataBeingSent ? (
        <button type="button" className="cta cta--accent--clicked">
          Review
        </button>
      ) : null}
      {environment === "assessor" && !formDataBeingSent ? (
        <button
          type="button"
          className="cta cta--accent"
          onClick={handleValidateSectionAssessor}
        >
          Review
        </button>
      ) : null}
      {environment === "candidate" && !formDataBeingSent ? (
        <button
          type="button"
          className="cta cta--accent"
          onClick={handleValidateSectionCandidate}
        >
          Review
        </button>
      ) : null}
    </form>
  );
}

export default withContext(SectionF);
