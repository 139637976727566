// ========== imports ========== //
import "./NavbarHome.css";

import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { RxHamburgerMenu, RxCross1 } from "react-icons/rx";
// import { AiFillTwitterCircle } from "react-icons/ai";
import { BsFacebook, BsInstagram, BsTwitter, BsLinkedin } from "react-icons/bs";

import logo from "../../Assets/castLogo1.png";

import { Link } from "react-router-dom";
import { Cta } from "../Common";

// ========== component ========== //
function NavbarHome({ aboutMe }) {
  // ========== hook consts ========== //

  const [isActive, setIsActive] = useState(false);
  const navigate = useNavigate();
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleWindowResize);

    // Return a function from the effect that removes the event listener
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);
  const breakpoint = 1024;
  // ========== other consts ========== //

  const routes = [
    {
      route: "/home",
      component: "/Home",
      key: "/Home",
      title: "Home",
    },
    {
      route: "/purpose",
      component: "/OurPurposeAndWhoWeAre",
      key: "/Purpose",
      title: "Purpose",
    },
    {
      route: "/insights",
      component: "/CastInsights",
      key: "/Insights",
      title: "Insights",
    },
    {
      route: "/assessment",
      component: "/TheAssessment",
      key: "/Assessment",
      title: "Assessment",
    },
    {
      route: "/services",
      component: "/ServiceOptionsAndPrices",
      key: "/Services",
      title: "Services",
    },
    {
      route: "/news",
      component: "/News",
      key: "/News",
      title: "News & Resources",
    },
  ];

  // ========== jsx ========== //
  return (
    <nav
      className={`nav collapsible ${isActive ? "collapsible--expanded" : null}`}
    >
      <div className="nav__bar">
        {width < breakpoint && (
          <div className="nav__toggler-container">
            <div className="logo">
              <Link to="/home">
                <img className="logo" src={logo} alt="" />
              </Link>
            </div>
            <div className="nav__icons--mobile">
              <Cta text="Contact us" type="cta--header" to="/contact" />
              <div>
                {/* SOCIAL MEDIA ICONS AND LINKS FOR FUTURE USE - MOBILE */}
                {/*   <a
                  href="https://twitter.com/PaTassociates"
                  title="Follow us on Twitter (opens new tab)"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <BsTwitter className="nav__media-icon" />
                </a>
                <a
                  href="https://www.linkedin.com/in/patricktomlinsonassociates/"
                  title="Follow us on LinkedIn (opens new tab)"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <BsLinkedin className="nav__media-icon" />
                </a>
                <a
                  href="https://www.instagram.com/patrick_tomlinson_associates/"
                  title="Follow us on Instagram (opens new tab)"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <BsInstagram className="nav__media-icon" />
                </a> */}
                {!isActive ? (
                  <RxHamburgerMenu
                    className="icon nav__toggler"
                    onClick={() => setIsActive(!isActive)}
                  />
                ) : (
                  <RxCross1
                    className="icon nav__toggler"
                    onClick={() => setIsActive(!isActive)}
                  />
                )}
              </div>
            </div>
          </div>
        )}
      </div>
      <ul
        className={`list nav__list ${
          width < breakpoint ? "collapsible__content" : null
        }`}
      >
        {routes.map((element) => {
          return (
            <li
              key={element.key}
              onClick={() => width < breakpoint && setIsActive(!isActive)}
              className="nav__item"
            >
              {/* TODO: change his button to Link */}
              <button
                // TODO: style menu text, wrap text, change text to add whole phrase and spaces, commas etc...
                onClick={() =>
                  navigate(`${element.route.slice(1).toLowerCase()}`)
                }
              >
                {element.title}
              </button>
            </li>
          );
        })}
      </ul>
    </nav>
  );
}

export default NavbarHome;
