import Joi from "joi";

const schemaFormSectionA = Joi.object({
  A1a: Joi.string().trim().max(1).valid(null, "", "0", "1", "2", "3").messages({
    "string.base": "please select a valid response",
    "string.empty": "please select a valid response",
  }),
  A1a_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
    "string.base": "please write your response to a maximum of 2000 characters",
    "string.empty":
      "please write your response to a maximum of 2000 characters",
  }),
  A1a_score: Joi.string()
    .trim()
    .max(1)
    .valid(null, "", "0", "1", "2", "3")
    .messages({
      "string.base": "please select a valid response",
      "string.empty": "please select a valid response",
    }),
  A1b: Joi.string().trim().max(1).valid(null, "", "0", "1", "2", "3").messages({
    "string.base": "please select a valid response",
    "string.empty": "please select a valid response",
  }),
  A1b_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
    "string.base": "please write your response to a maximum of 2000 characters",
    "string.empty":
      "please write your response to a maximum of 2000 characters",
  }),
  A1b_score: Joi.string()
    .trim()
    .max(1)
    .valid(null, "", "0", "1", "2", "3")
    .messages({
      "string.base": "please select a valid response",
      "string.empty": "please select a valid response",
    }),
  A2: Joi.string().trim().max(1).valid(null, "", "0", "1", "2", "3").messages({
    "string.base": "please select a valid response",
    "string.empty": "please select a valid response",
  }),
  A2_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
    "string.base": "please write your response to a maximum of 2000 characters",
    "string.empty":
      "please write your response to a maximum of 2000 characters",
  }),
  A2_score: Joi.string()
    .trim()
    .max(1)
    .valid(null, "", "0", "1", "2", "3")
    .messages({
      "string.base": "please select a valid response",
      "string.empty": "please select a valid response",
    }),
  A3: Joi.string().trim().max(1).valid(null, "", "0", "1", "2", "3").messages({
    "string.base": "please select a valid response",
    "string.empty": "please select a valid response",
  }),
  A3_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
    "string.base": "please write your response to a maximum of 2000 characters",
    "string.empty":
      "please write your response to a maximum of 2000 characters",
  }),
  A3_score: Joi.string()
    .trim()
    .max(1)
    .valid(null, "", "0", "1", "2", "3")
    .messages({
      "string.base": "please select a valid response",
      "string.empty": "please select a valid response",
    }),
  A4: Joi.string().trim().max(1).valid(null, "", "0", "1", "2", "3").messages({
    "string.base": "please select a valid response",
    "string.empty": "please select a valid response",
  }),
  A4_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
    "string.base": "please write your response to a maximum of 2000 characters",
    "string.empty":
      "please write your response to a maximum of 2000 characters",
  }),
  A4_score: Joi.string()
    .trim()
    .max(1)
    .valid(null, "", "0", "1", "2", "3")
    .messages({
      "string.base": "please select a valid response",
      "string.empty": "please select a valid response",
    }),
  A5: Joi.string().trim().max(1).valid(null, "", "0", "1", "2", "3").messages({
    "string.base": "please select a valid response",
    "string.empty": "please select a valid response",
  }),
  A5_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
    "string.base": "please write your response to a maximum of 2000 characters",
    "string.empty":
      "please write your response to a maximum of 2000 characters",
  }),
  A5_score: Joi.string()
    .trim()
    .max(1)
    .valid(null, "", "0", "1", "2", "3")
    .messages({
      "string.base": "please select a valid response",
      "string.empty": "please select a valid response",
    }),
  A6: Joi.string().trim().max(1).valid(null, "", "0", "1", "2", "3").messages({
    "string.base": "please select a valid response",
    "string.empty": "please select a valid response",
  }),
  A6_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
    "string.base": "please write your response to a maximum of 2000 characters",
    "string.empty":
      "please write your response to a maximum of 2000 characters",
  }),
  A6_score: Joi.string()
    .trim()
    .max(1)
    .valid(null, "", "0", "1", "2", "3")
    .messages({
      "string.base": "please select a valid response",
      "string.empty": "please select a valid response",
    }),
});

export default schemaFormSectionA;
