// ========== imports ========== //

import React, { useRef, useState, useEffect, useCallback } from "react";

import { useParams, useLocation } from "react-router-dom";

import useAnalyticsPageViewSenderGA4 from "../../../../utils/useAnalyticsPageViewSenderGA4";

import Loggito from "../../../../utils/Loggito";
import withContext from "../../../../utils/withContext";

import { updateCastFormResponse } from "../../../../logic";

import { toast } from "react-toastify";

import { IoWarningOutline } from "react-icons/io5";

import { ServerError } from "errors";

import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";

import {
  schemaFormSectionD,
  validateSectionDAssessor,
  validateSectionDCandidate,
} from "../../../../validators";

import {
  QuestionAssessmentContainer,
  QuestionMcq14Strongly,
  QuestionMcqListHorizontal,
  QuestionTextBox,
  QuestionShortText,
  QuestionMcqYesNo,
} from "./FormComponents";

// ========== Page ========== //

function SectionD({ environment, onApproveSection, existingValues }) {
  // ========== Hook consts ========== //
  const [formDataBeingSent, setFormDataBeingSent] = useState(true);

  const [errorsAssessor, setErrorsAssessor] = useState({});
  const [errorsCandidate, setErrorsCandidate] = useState({});

  const form = useRef();
  const location = useLocation();

  const gaPageViewSender = useAnalyticsPageViewSenderGA4();
  useEffect(() => {
    gaPageViewSender("CAST Form Dev");
  }, []);

  const {
    register: registerD,
    handleSubmit: handleSubmitD,
    reset,
    formState: { errors },
    getValues: getValuesD,
    watch,
    setValue,
  } = useForm({
    resolver: joiResolver(schemaFormSectionD),
    reValidateMode: "onChange",
  });

  // THIS WORKS - VALUES MUST BE DEALT WITH SEPARATELY FOR SETVALUE

  useEffect(() => {
    if (Object.entries(existingValues).length > 0) {
      for (const [key, value] of Object.entries(existingValues)) {
        /*  if (key[0] === "D" && value) {
            setValue(key, value.response, {});
            // TODO: investigate the benefit of these attributes
            // shouldValidate: true,
            // shouldDirty: true,
          } */
        if (key[0] === "D") {
          if (value.response) setValue(key, value.response, {});
          if (value.score) setValue(key + "_score", value.score, {});
          if (value.comment) setValue(key + "_comment", value.comment, {});
        }
      }
      setFormDataBeingSent(false);
    }
  }, [existingValues]);

  const [userText, setUserText] = useState("");

  const handleUserKeyPress = useCallback((event) => {
    const { key, keyCode } = event;

    if (keyCode === 32 || (keyCode >= 65 && keyCode <= 90)) {
      setUserText(key);
    }
  }, []);

  useEffect(() => {
    const delayFn = setTimeout(() => saveFormData(null), 3000);
    return () => clearTimeout(delayFn);
  }, [userText]);

  useEffect(() => {
    // advised to create a constant variable as the form.current is likely to change before cleanup
    const formReferenced = form.current;
    formReferenced.addEventListener("keydown", handleUserKeyPress);

    // cleanup on dismount component
    return () => {
      formReferenced.removeEventListener("keydown", handleUserKeyPress);
    };
  }, [handleUserKeyPress]);

  // END SUBMIT AFTER TYPING

  const params = useParams();
  let route = params.route;

  const saveFormData = async (approved) => {
    const questionNumbersArray = [
      "D1",
      "D2",
      "D3",
      "D4",
      "D5",
      "D6",
      "D7a",
      "D7b",
      "D8",
      "D9",
      "D10a",
      "D10b",
    ];

    const values = getValuesD();

    let groupedValuesObject = {};

    questionNumbersArray.forEach((questionNumber) => {
      const scoreKey = questionNumber + "_score";
      const commentKey = questionNumber + "_comment";
      groupedValuesObject = {
        ...groupedValuesObject,
        [questionNumber]: {
          response: values[questionNumber],
          score: values[scoreKey],
          comment: values[commentKey],
        },
      };
    });

    console.log(values);

    if (approved === "approved")
      groupedValuesObject.detailsFormSectionApproved = "sectionD";

    let endpoint;

    if (location.pathname.slice(1).slice(0, 5) === "admin") {
      endpoint = "assessor";
    } else if (
      location.pathname.slice(1).slice(0, 5) === "admin" &&
      environment === "candidate"
    ) {
      endpoint = "candidateassessor";
    } else endpoint = "candidate";

    setFormDataBeingSent(true);
    try {
      updateCastFormResponse(
        sessionStorage,
        route,
        groupedValuesObject,
        endpoint,
        function (error) {
          if (error) {
            if (error instanceof ServerError) {
              toast.error(error.message);
              logger.error(error.message);
            } else {
              toast.warn(error.message);
              logger.warn(error.message);
            }
            return;
          }
          toast.success("Your answers have been saved");
        }
      );
    } catch (error) {
      logger.warn(error.message);
      toast.warn(error.message);
    }

    setFormDataBeingSent(false);
  };

  // THIS SEPARATE VALIDATION HANDLES THE FINAL REVIEW TO CLOSE THE SECTION AND MOVE ONTO THE NEXT

  const handleValidateSectionAssessor = () => {
    const formValues = getValuesD();
    const errors = validateSectionDAssessor(formValues);
    if (errors) {
      setErrorsAssessor(errors);
      return;
    } else {
      // HERE THE DATA SHOULD BE SAVED ONE FINAL TIME AND THEN THE FOLLWING STEPS, CLOSING THE SECTION AND MOVING ONTO THE FOLLOWING SECTION...
      setErrorsAssessor({});
      saveFormData(null);
      alert("Form Review OK and final data submitted");
      if (environment === "candidate") onApproveSection("sectionD");
      return;
    }
  };

  const handleValidateSectionCandidate = () => {
    const formValues = getValuesD();
    const errors = validateSectionDCandidate(formValues);
    if (errors) {
      setErrorsCandidate(errors);
      return;
    } else {
      // HERE THE DATA SHOULD BE SAVED ONE FINAL TIME AND THEN THE FOLLWING STEPS, CLOSING THE SECTION AND MOVING ONTO THE FOLLOWING SECTION...
      setErrorsCandidate({});
      saveFormData("approved");
      alert("Form Review OK and final data submitted");
      onApproveSection("sectionD");
      return;
    }
  };

  // ========== other consts ========== //

  const logger = new Loggito("Form section D");

  // ========== useEffects ========== //

  // ========== jsx ========== //

  return (
    <form
      ref={form}
      // onSubmit={handleSubmitD((data) => saveFormData())}
      onSubmit={(event) => {
        event.preventDefault();
        handleSubmitD((data) => saveFormData());
      }}
      className="cast-form"
      noValidate={true}
    >
      {(Object.keys(errorsAssessor).length !== 0 &&
        errorsAssessor.constructor === Object) ||
      (Object.keys(errorsCandidate).length !== 0 &&
        errorsCandidate.constructor === Object) ? (
        <div className="error-block">
          <IoWarningOutline className="icon" />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <p className="p--m">
            There are questions that require your attention
          </p>
        </div>
      ) : null}
      <div className="cast-form__title-block">
        <h2 className="h2">Section D</h2>
        <p className="p--m">
          This section has two types of questions; some where you will be asked
          to write down your answers and some will be statements that you score
          from 0-3 . Score these statements from 0-3 based on how much you agree
          or disagree with them.
          <br />
          <br />
          0 strongly disagree <br />1 disagree, <br />2 agree <br />3 strongly
          agree
          <br /> <br />
          On the first 4 questions think of the general trend rather than the
          exception to the rule.
          <br />
          &nbsp;
        </p>
      </div>
      <QuestionMcq14Strongly
        onSubmit={handleSubmitD((data) => saveFormData())}
        question={"D1"}
        questionText={
          "Taking responsibility tends to be a trait of successful people and blaming tends to be a trait of unsuccessful people."
        }
        register={registerD}
        qErrors={errors.D1}
        qErrorsAssessor={errorsAssessor.D1}
        qErrorsCandidate={errorsCandidate.D1}
      />
      {environment === "assessor" ? (
        <QuestionAssessmentContainer
          onSubmit={handleSubmitD((data) => saveFormData())}
          question={"D1"}
          watch={watch}
          scoreType={"Automatically scored"}
          scoreExplanation={""}
          register={registerD}
          qErrorsAssessor={{
            score: errorsAssessor.D1_score,
            comment: errorsAssessor.D1_comment,
          }}
        />
      ) : null}
      <QuestionMcq14Strongly
        onSubmit={handleSubmitD((data) => saveFormData())}
        question={"D2"}
        questionText={
          "Where you end up in life is dictated by the choices you make."
        }
        register={registerD}
        qErrors={errors.D2}
        qErrorsAssessor={errorsAssessor.D2}
        qErrorsCandidate={errorsCandidate.D2}
      />
      {environment === "assessor" ? (
        <QuestionAssessmentContainer
          onSubmit={handleSubmitD((data) => saveFormData())}
          question={"D2"}
          watch={watch}
          scoreType={"Automatically scored"}
          scoreExplanation={""}
          register={registerD}
          qErrorsAssessor={{
            score: errorsAssessor.D2_score,
            comment: errorsAssessor.D2_comment,
          }}
        />
      ) : null}
      <QuestionMcq14Strongly
        onSubmit={handleSubmitD((data) => saveFormData())}
        question={"D3"}
        questionText={
          "Success is not the key to happiness, happiness is the key to success."
        }
        register={registerD}
        qErrors={errors.D3}
        qErrorsAssessor={errorsAssessor.D3}
        qErrorsCandidate={errorsCandidate.D3}
      />
      {environment === "assessor" ? (
        <QuestionAssessmentContainer
          onSubmit={handleSubmitD((data) => saveFormData())}
          question={"D3"}
          watch={watch}
          scoreType={"Automatically scored"}
          scoreExplanation={""}
          register={registerD}
          qErrorsAssessor={{
            score: errorsAssessor.D3_score,
            comment: errorsAssessor.D3_comment,
          }}
        />
      ) : null}
      <QuestionMcq14Strongly
        onSubmit={handleSubmitD((data) => saveFormData())}
        question={"D4"}
        questionText={
          "I can fail many times, but not become a failure until I start to blame others."
        }
        register={registerD}
        qErrors={errors.D4}
        qErrorsAssessor={errorsAssessor.D4}
        qErrorsCandidate={errorsCandidate.D4}
      />
      {environment === "assessor" ? (
        <QuestionAssessmentContainer
          onSubmit={handleSubmitD((data) => saveFormData())}
          question={"D4"}
          watch={watch}
          scoreType={"Automatically scored"}
          scoreExplanation={""}
          register={registerD}
          qErrorsAssessor={{
            score: errorsAssessor.D4_score,
            comment: errorsAssessor.D4_comment,
          }}
        />
      ) : null}
      <QuestionMcqListHorizontal
        onSubmit={handleSubmitD((data) => saveFormData())}
        question={"D5"}
        questionText={
          "Please think of a dimension with pessimism at one end and optimism at the other end, with realist in the middle, where do you place yourself?"
        }
        register={registerD}
        qErrors={errors.D5}
        qErrorsAssessor={errorsAssessor.D5}
        qErrorsCandidate={errorsCandidate.D5}
        labels={[
          "Pessimist",
          "Pessiminst-Realist",
          "Realist",
          "Realist-Optimist",
          "Optimist",
        ]}
      />
      {environment === "assessor" ? (
        <QuestionAssessmentContainer
          onSubmit={handleSubmitD((data) => saveFormData())}
          question={"D5"}
          watch={watch}
          scoreType={"Automatically scored"}
          scoreExplanation={""}
          register={registerD}
          qErrorsAssessor={{
            score: errorsAssessor.D5_score,
            comment: errorsAssessor.D5_comment,
          }}
        />
      ) : null}
      <QuestionTextBox
        question={"D6"}
        questionText={
          "Please say if you are currently learning and developing yourself and if so how?"
        }
        register={registerD}
        qErrors={errors.D6}
        qErrorsAssessor={errorsAssessor.D6}
        qErrorsCandidate={errorsCandidate.D6}
      />
      {environment === "assessor" ? (
        <QuestionAssessmentContainer
          onSubmit={handleSubmitD((data) => saveFormData())}
          question={"D6"}
          watch={watch}
          scoreType={"Assessor scored"}
          scoreExplanation={""}
          register={registerD}
          qErrorsAssessor={{
            score: errorsAssessor.D6_score,
            comment: errorsAssessor.D6_comment,
          }}
        />
      ) : null}
      <QuestionMcqYesNo
        onSubmit={handleSubmitD((data) => saveFormData())}
        question={"D7a"}
        questionText={
          "Do you have a habit you would like to change and if so, do you have a plan for how to change it?"
        }
        register={registerD}
        qErrors={errors.D7a}
        qErrorsAssessor={errorsAssessor.D7a}
        qErrorsCandidate={errorsCandidate.D7a}
      />
      {environment === "assessor" ? (
        <QuestionAssessmentContainer
          onSubmit={handleSubmitD((data) => saveFormData())}
          question={"D7a"}
          watch={watch}
          scoreType={"Automatically scored"}
          scoreExplanation={""}
          register={registerD}
          qErrorsAssessor={{
            score: errorsAssessor.D7a_score,
            comment: errorsAssessor.D7a_comment,
          }}
        />
      ) : null}
      <QuestionTextBox
        question={"D7b"}
        questionText={
          "Please say if you are currently learning and developing yourself and if so how?"
        }
        register={registerD}
        qErrors={errors.D7b}
        qErrorsAssessor={errorsAssessor.D7b}
        qErrorsCandidate={errorsCandidate.D7b}
      />
      {environment === "assessor" ? (
        <QuestionAssessmentContainer
          onSubmit={handleSubmitD((data) => saveFormData())}
          question={"D7b"}
          watch={watch}
          scoreType={"Assessor scored"}
          scoreExplanation={""}
          register={registerD}
          qErrorsAssessor={{
            score: errorsAssessor.D7b_score,
            comment: errorsAssessor.D7b_comment,
          }}
        />
      ) : null}
      <QuestionShortText
        question={"D8"}
        questionText={"What things do you feel gratitude for, and why?"}
        register={registerD}
        qErrors={errors.D8}
        qErrorsAssessor={errorsAssessor.D8}
        qErrorsCandidate={errorsCandidate.D8}
      />
      {environment === "assessor" ? (
        <QuestionAssessmentContainer
          onSubmit={handleSubmitD((data) => saveFormData())}
          question={"D8"}
          watch={watch}
          scoreType={"Assessor scored"}
          scoreExplanation={""}
          register={registerD}
          qErrorsAssessor={{
            score: errorsAssessor.D8_score,
            comment: errorsAssessor.D8_comment,
          }}
        />
      ) : null}
      <QuestionShortText
        question={"D9"}
        questionText={"What does it mean to you to have 'courage' at work?"}
        register={registerD}
        qErrors={errors.D9}
        qErrorsAssessor={errorsAssessor.D9}
        qErrorsCandidate={errorsCandidate.D9}
      />
      {environment === "assessor" ? (
        <QuestionAssessmentContainer
          onSubmit={handleSubmitD((data) => saveFormData())}
          question={"D9"}
          watch={watch}
          scoreType={"Assessor scored"}
          scoreExplanation={""}
          register={registerD}
          qErrorsAssessor={{
            score: errorsAssessor.D9_score,
            comment: errorsAssessor.D9_comment,
          }}
        />
      ) : null}
      <QuestionShortText
        question={"D10a"}
        questionText={
          "Imagine this scenario: You can’t get all the work done that is expected of you. What would be the typical reason if you were not able to complete your work?"
        }
        register={registerD}
        qErrors={errors.D10a}
        qErrorsAssessor={errorsAssessor.D10a}
        qErrorsCandidate={errorsCandidate.D10a}
      />
      {environment === "assessor" ? (
        <QuestionAssessmentContainer
          onSubmit={handleSubmitD((data) => saveFormData())}
          question={"D10a"}
          watch={watch}
          scoreType={"Assessor scored"}
          scoreExplanation={""}
          register={registerD}
          qErrorsAssessor={{
            score: errorsAssessor.D10a_score,
            comment: errorsAssessor.D10a_comment,
          }}
        />
      ) : null}
      <QuestionShortText
        question={"D10b"}
        questionText={
          "In the scenario above, how would you (typically) deal with not completing the work that was expected of you?"
        }
        register={registerD}
        qErrors={errors.D10b}
        qErrorsAssessor={errorsAssessor.D10b}
        qErrorsCandidate={errorsCandidate.D10b}
      />
      {environment === "assessor" ? (
        <QuestionAssessmentContainer
          onSubmit={handleSubmitD((data) => saveFormData())}
          question={"D10b"}
          watch={watch}
          scoreType={"Assessor scored"}
          scoreExplanation={""}
          register={registerD}
          qErrorsAssessor={{
            score: errorsAssessor.D10b_score,
            comment: errorsAssessor.D10b_comment,
          }}
        />
      ) : null}

      {formDataBeingSent ? (
        <button type="button" className="cta cta--accent--clicked">
          Review
        </button>
      ) : null}
      {environment === "assessor" && !formDataBeingSent ? (
        <button
          type="button"
          className="cta cta--accent"
          onClick={handleValidateSectionAssessor}
        >
          Review
        </button>
      ) : null}
      {environment === "candidate" && !formDataBeingSent ? (
        <button
          type="button"
          className="cta cta--accent"
          onClick={handleValidateSectionCandidate}
        >
          Review
        </button>
      ) : null}
    </form>
  );
}

export default withContext(SectionD);
