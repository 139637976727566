const { FormatError } = require("errors");

const Joi = require("joi");

function validateSectionAAssessor(formValues) {
  // THE SCHEMA HERE HAS THE SAME STRUCTURE AS THE FORM SCHEMAS

  const schema = Joi.object({
    A1a: Joi.string().trim().max(1).valid("0", "1", "2", "3").messages({
      "string.base": "please select a valid response",
      "string.empty": "please select a valid response",
    }),
    A1a_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
    }),
    A1a_score: Joi.string().trim().max(1).valid("0", "1", "2", "3").messages({
      "string.base": "please select a valid response",
      "string.empty": "please select a valid response",
    }),
    A1b: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
    }),
    A1b_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
    }),
    A1b_score: Joi.string().trim().max(1).valid("0", "1", "2", "3").messages({
      "string.base": "please select a valid response",
      "string.empty": "please select a valid response",
    }),
    A2: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
    }),
    A2_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
    }),
    A2_score: Joi.string().trim().max(1).valid("0", "1", "2", "3").messages({
      "string.base": "please select a valid response",
      "string.empty": "please select a valid response",
    }),
    A3: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
    }),
    A3_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
    }),
    A3_score: Joi.string().trim().max(1).valid("0", "1", "2", "3").messages({
      "string.base": "please select a valid response",
      "string.empty": "please select a valid response",
    }),
    A4: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
    }),
    A4_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
    }),
    A4_score: Joi.string().trim().max(1).valid("0", "1", "2", "3").messages({
      "string.base": "please select a valid response",
      "string.empty": "please select a valid response",
    }),
    A5: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
    }),
    A5_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
    }),
    A5_score: Joi.string().trim().max(1).valid("0", "1", "2", "3").messages({
      "string.base": "please select a valid response",
      "string.empty": "please select a valid response",
    }),
    A6: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
    }),
    A6_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
    }),
    A6_score: Joi.string().trim().max(1).valid("0", "1", "2", "3").messages({
      "string.base": "please select a valid response",
      "string.empty": "please select a valid response",
    }),
  });

  const options = { abortEarly: false };

  const { error } = schema.validate(formValues, options);

  if (!error) return null;

  const errors = {};
  for (let item of error.details) {
    errors[item.path[0]] = item.message;
  }
  return errors;
}

export default validateSectionAAssessor;
