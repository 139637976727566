// ========== imports ========== //

import React, { useRef, useState, useEffect, useCallback } from "react";

import { useParams, useLocation } from "react-router-dom";

import useAnalyticsPageViewSenderGA4 from "../../../../utils/useAnalyticsPageViewSenderGA4";

import Loggito from "../../../../utils/Loggito";
import withContext from "../../../../utils/withContext";

import { updateCastFormResponse } from "../../../../logic";

import { toast } from "react-toastify";

import { IoWarningOutline } from "react-icons/io5";

import { ServerError } from "errors";

import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";

import {
  schemaFormSectionB,
  validateSectionBAssessor,
  validateSectionBCandidate,
} from "../../../../validators";

import {
  QuestionAssessmentContainer,
  QuestionMcqYesNoMaybe,
  QuestionShortText,
  QuestionTextBox,
  QuestionMcqListHorizontalB1,
} from "./FormComponents";

// ========== Page ========== //

function SectionB({ environment, onApproveSection, existingValues }) {
  // ========== Hook consts ========== //
  const [formDataBeingSent, setFormDataBeingSent] = useState(true);

  const [errorsAssessor, setErrorsAssessor] = useState({});
  const [errorsCandidate, setErrorsCandidate] = useState({});

  const form = useRef();
  const location = useLocation();

  const gaPageViewSender = useAnalyticsPageViewSenderGA4();
  useEffect(() => {
    gaPageViewSender("CAST Form Dev");
  }, []);

  const {
    register: registerB,
    handleSubmit: handleSubmitB,
    reset,
    formState: { errors },
    getValues: getValuesB,
    watch,
    setValue,
  } = useForm({
    resolver: joiResolver(schemaFormSectionB),
    reValidateMode: "onChange",
  });

  // THIS WORKS - VALUES MUST BE DEALT WITH SEPARATELY FOR SETVALUE

  useEffect(() => {
    if (Object.entries(existingValues).length > 0) {
      for (const [key, value] of Object.entries(existingValues)) {
        /*  if (key[0] === "B" && value) {
          setValue(key, value.response, {});
          // TODO: investigate the benefit of these attributes
            // shouldValidate: true,
            // shouldDirty: true,
        } */
        if (key[0] === "B") {
          if (value.response) setValue(key, value.response, {});
          if (value.score) setValue(key + "_score", value.score, {});
          if (value.comment) setValue(key + "_comment", value.comment, {});
        }
      }
      setFormDataBeingSent(false);
    }
  }, [existingValues]);

  const [userText, setUserText] = useState("");

  const handleUserKeyPress = useCallback((event) => {
    const { key, keyCode } = event;

    if (keyCode === 32 || (keyCode >= 65 && keyCode <= 90)) {
      setUserText(key);
    }
  }, []);

  useEffect(() => {
    const delayFn = setTimeout(() => saveFormData(null), 3000);
    return () => clearTimeout(delayFn);
  }, [userText]);

  useEffect(() => {
    // advised to create a constant variable as the form.current is likely to change before cleanup
    const formReferenced = form.current;
    formReferenced.addEventListener("keydown", handleUserKeyPress);

    // cleanup on dismount component
    return () => {
      formReferenced.removeEventListener("keydown", handleUserKeyPress);
    };
  }, [handleUserKeyPress]);

  // END SUBMIT AFTER TYPING

  const params = useParams();
  let route = params.route;

  const saveFormData = async (approved) => {
    const questionNumbersArray = [
      "B1a",
      "B1b",
      "B1c",
      "B1d",
      "B1e",
      "B2",
      "B3",
      "B4a",
      "B4b",
      "B5",
      "B6",
      "B7",
      "B8",
      "B9a",
      "B9b",
      "B10",
      "B11",
      "B12a",
      "B12b",
    ];

    const values = getValuesB();

    let groupedValuesObject = {};
    questionNumbersArray.forEach((questionNumber) => {
      const scoreKey = questionNumber + "_score";
      const commentKey = questionNumber + "_comment";
      groupedValuesObject = {
        ...groupedValuesObject,
        [questionNumber]: {
          response: values[questionNumber],
          score: values[scoreKey],
          comment: values[commentKey],
        },
      };
    });

    console.log(values);

    if (approved === "approved")
      groupedValuesObject.detailsFormSectionApproved = "sectionB";

    let endpoint;

    if (location.pathname.slice(1).slice(0, 5) === "admin") {
      endpoint = "assessor";
    } else if (
      location.pathname.slice(1).slice(0, 5) === "admin" &&
      environment === "candidate"
    ) {
      endpoint = "candidateassessor";
    } else endpoint = "candidate";

    setFormDataBeingSent(true);
    try {
      updateCastFormResponse(
        sessionStorage,
        route,
        groupedValuesObject,
        endpoint,
        function (error) {
          if (error) {
            if (error instanceof ServerError) {
              toast.error(error.message);
              logger.error(error.message);
            } else {
              toast.warn(error.message);
              logger.warn(error.message);
            }
            return;
          }
          toast.success("Your answers have been saved");
        }
      );
    } catch (error) {
      logger.warn(error.message);
      toast.warn(error.message);
    }

    setFormDataBeingSent(false);
  };

  // THIS SEPARATE VALIDATION HANDLES THE FINAL REVIEW TO CLOSE THE SECTION AND MOVE ONTO THE NEXT

  const handleValidateSectionAssessor = () => {
    const formValues = getValuesB();
    const errors = validateSectionBAssessor(formValues);
    if (errors) {
      setErrorsAssessor(errors);
      return;
    } else {
      // HERE THE DATA SHOULD BE SAVED ONE FINAL TIME AND THEN THE FOLLWING STEPS, CLOSING THE SECTION AND MOVING ONTO THE FOLLOWING SECTION...
      setErrorsAssessor({});
      saveFormData(null);
      alert("Form Review OK and final data submitted");
      if (environment === "candidate") onApproveSection("sectionB");
      return;
    }
  };

  const handleValidateSectionCandidate = () => {
    const formValues = getValuesB();
    const errors = validateSectionBCandidate(formValues);
    if (errors) {
      setErrorsCandidate(errors);
      return;
    } else {
      // HERE THE DATA SHOULD BE SAVED ONE FINAL TIME AND THEN THE FOLLWING STEPS, CLOSING THE SECTION AND MOVING ONTO THE FOLLOWING SECTION...
      setErrorsCandidate({});
      saveFormData("approved");
      alert("Form Review OK and final data submitted");
      onApproveSection("sectionB");
      return;
    }
  };

  // ========== other consts ========== //

  const logger = new Loggito("Form section B");

  // ========== useEffects ========== //

  // ========== jsx ========== //

  return (
    <form
      ref={form}
      // onSubmit={handleSubmitB((data) => saveFormData())}
      onSubmit={(event) => {
        event.preventDefault();
        handleSubmitB((data) => saveFormData());
      }}
      className="cast-form"
      noValidate={true}
    >
      {(Object.keys(errorsAssessor).length !== 0 &&
        errorsAssessor.constructor === Object) ||
      (Object.keys(errorsCandidate).length !== 0 &&
        errorsCandidate.constructor === Object) ? (
        <div className="error-block">
          <IoWarningOutline className="icon" />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <p className="p--m">
            There are questions that require your attention
          </p>
        </div>
      ) : null}
      <div className="cast-form__title-block">
        <h2 className="h2">Section B</h2>
        <p className="p--m">
          Through the next questions, you will be asked a bit about your life
          and the challenges you have faced.
          <br />
          &nbsp;
        </p>
      </div>
      <QuestionMcqListHorizontalB1
        onSubmit={handleSubmitB((data) => saveFormData())}
        question={"B1a"}
        questionText={
          "How many different jobs have you had since you started full time work? (A change of job within the same organisation counts as one. Being self-employed also counts as one.)"
        }
        register={registerB}
        qErrors={errors.B1a}
        qErrorsAssessor={errorsAssessor.B1a}
        qErrorsCandidate={errorsCandidate.B1a}
      />
      {environment === "assessor" ? (
        <QuestionAssessmentContainer
          onSubmit={handleSubmitB((data) => saveFormData())}
          question={"B1a"}
          watch={watch}
          scoreType={"Automatically scored"}
          scoreExplanation={""}
          register={registerB}
          qErrorsAssessor={{
            score: errorsAssessor.B1a_score,
            comment: errorsAssessor.B1a_comment,
          }}
        />
      ) : null}
      <QuestionShortText
        question={"B1b"}
        questionText={
          "Have you ever been promoted? What is the highest level of position you have held?"
        }
        register={registerB}
        qErrors={errors.B1b}
        qErrorsAssessor={errorsAssessor.B1b}
        qErrorsCandidate={errorsCandidate.B1b}
      />
      {environment === "assessor" ? (
        <QuestionAssessmentContainer
          onSubmit={handleSubmitB((data) => saveFormData())}
          question={"B1b"}
          watch={watch}
          scoreType={"Assessor scored"}
          scoreExplanation={""}
          register={registerB}
          qErrorsAssessor={{
            score: errorsAssessor.B1b_score,
            comment: errorsAssessor.B1b_comment,
          }}
        />
      ) : null}
      <QuestionShortText
        question={"B1c"}
        questionText={
          "What is the shortest you have ever been in one job and what is the longest you have ever been in one job?"
        }
        register={registerB}
        qErrors={errors.B1c}
        qErrorsAssessor={errorsAssessor.B1c}
        qErrorsCandidate={errorsCandidate.B1c}
      />
      {environment === "assessor" ? (
        <QuestionAssessmentContainer
          onSubmit={handleSubmitB((data) => saveFormData())}
          question={"B1c"}
          watch={watch}
          scoreType={"Assessor scored"}
          scoreExplanation={""}
          register={registerB}
          qErrorsAssessor={{
            score: errorsAssessor.B1c_score,
            comment: errorsAssessor.B1c_comment,
          }}
        />
      ) : null}
      <QuestionShortText
        question={"B1d"}
        questionText={
          "How many years have you been in full-time employment (If you have been absent due to maternity leave or long-term sickness please state how long)? Do not include jobs that you did to support training, or getting a qualification, for example."
        }
        register={registerB}
        qErrors={errors.B1d}
        qErrorsAssessor={errorsAssessor.B1d}
        qErrorsCandidate={errorsCandidate.B1d}
      />
      {environment === "assessor" ? (
        <QuestionAssessmentContainer
          onSubmit={handleSubmitB((data) => saveFormData())}
          question={"B1d"}
          watch={watch}
          scoreType={"Assessor scored"}
          scoreExplanation={""}
          register={registerB}
          qErrorsAssessor={{
            score: errorsAssessor.B1d_score,
            comment: errorsAssessor.B1d_comment,
          }}
        />
      ) : null}
      <QuestionTextBox
        question={"B1e"}
        questionText={
          "If you have stayed at the same job for your entire career, please explain the reason."
        }
        register={registerB}
        qErrors={errors.B1e}
        qErrorsAssessor={errorsAssessor.B1e}
        qErrorsCandidate={errorsCandidate.B1e}
      />
      {environment === "assessor" ? (
        <QuestionAssessmentContainer
          onSubmit={handleSubmitB((data) => saveFormData())}
          question={"B1e"}
          watch={watch}
          scoreType={"Assessor scored"}
          scoreExplanation={""}
          register={registerB}
          qErrorsAssessor={{
            score: errorsAssessor.B1e_score,
            comment: errorsAssessor.B1e_comment,
          }}
        />
      ) : null}
      <QuestionShortText
        question={"B2"}
        questionText={[
          "At any time in your life have you ever had any hobbies or activities that you have pursued for more than 2 years (outside of the regular school /work hours)? This can be any kind of pursuit, e.g. sports, music, clubs, volunteer activities, or hobbies.",
          "If so what and for how long?",
          "Did you progress your ability in these pursuits? Please explain if you took on a leadership or a co-ordinating role in either of these 2 interests?",
        ]}
        register={registerB}
        qErrors={errors.B2}
        qErrorsAssessor={errorsAssessor.B2}
        qErrorsCandidate={errorsCandidate.B2}
      />
      {environment === "assessor" ? (
        <QuestionAssessmentContainer
          onSubmit={handleSubmitB((data) => saveFormData())}
          question={"B2"}
          watch={watch}
          scoreType={"Assessor scored"}
          scoreExplanation={""}
          register={registerB}
          qErrorsAssessor={{
            score: errorsAssessor.B2_score,
            comment: errorsAssessor.B2_comment,
          }}
        />
      ) : null}
      <QuestionShortText
        question={"B3"}
        questionText={
          "How would you describe yourself in relation to routines at home and work?"
        }
        register={registerB}
        qErrors={errors.B3}
        qErrorsAssessor={errorsAssessor.B3}
        qErrorsCandidate={errorsCandidate.B3}
      />
      {environment === "assessor" ? (
        <QuestionAssessmentContainer
          onSubmit={handleSubmitB((data) => saveFormData())}
          question={"B3"}
          watch={watch}
          scoreType={"Assessor scored"}
          scoreExplanation={""}
          register={registerB}
          qErrorsAssessor={{
            score: errorsAssessor.B3_score,
            comment: errorsAssessor.B3_comment,
          }}
        />
      ) : null}
      <QuestionMcqYesNoMaybe
        onSubmit={handleSubmitB((data) => saveFormData())}
        question={"B4a"}
        questionText={
          "Do you think that qualities such as determination, perseverance, and purpose can be learned/developed through conscious effort?"
        }
        register={registerB}
        qErrors={errors.B4a}
        qErrorsAssessor={errorsAssessor.B4a}
        qErrorsCandidate={errorsCandidate.B4a}
      />
      {environment === "assessor" ? (
        <QuestionAssessmentContainer
          onSubmit={handleSubmitB((data) => saveFormData())}
          question={"B4a"}
          watch={watch}
          scoreType={"Automatically scored"}
          scoreExplanation={""}
          register={registerB}
          qErrorsAssessor={{
            score: errorsAssessor.B4a_score,
            comment: errorsAssessor.B4a_comment,
          }}
        />
      ) : null}
      <QuestionShortText
        question={"B4b"}
        questionText={
          "If you answered Yes to the previous question, how do you think they can be learned?"
        }
        register={registerB}
        qErrors={errors.B4b}
        qErrorsAssessor={errorsAssessor.B4b}
        qErrorsCandidate={errorsCandidate.B4b}
      />
      {environment === "assessor" ? (
        <QuestionAssessmentContainer
          onSubmit={handleSubmitB((data) => saveFormData())}
          question={"B4b"}
          watch={watch}
          scoreType={"Assessor scored"}
          scoreExplanation={""}
          register={registerB}
          qErrorsAssessor={{
            score: errorsAssessor.B4b_score,
            comment: errorsAssessor.B4b_comment,
          }}
        />
      ) : null}
      <QuestionShortText
        question={"B5"}
        questionText={
          "Please describe an example in your life where you have demonstrated resilience and determination. 5-10 sentences."
        }
        register={registerB}
        qErrors={errors.B5}
        qErrorsAssessor={errorsAssessor.B5}
        qErrorsCandidate={errorsCandidate.B5}
      />
      {environment === "assessor" ? (
        <QuestionAssessmentContainer
          onSubmit={handleSubmitB((data) => saveFormData())}
          question={"B5"}
          watch={watch}
          scoreType={"Assessor scored"}
          scoreExplanation={""}
          register={registerB}
          qErrorsAssessor={{
            score: errorsAssessor.B5_score,
            comment: errorsAssessor.B5_comment,
          }}
        />
      ) : null}
      <QuestionTextBox
        question={"B6"}
        questionText={[
          "Please describe an example in your life where you have demonstrated resilience and determination. 5-10 sentences.",
          "Please give an example that demonstrates some of these characteristics.",
        ]}
        register={registerB}
        qErrors={errors.B6}
        qErrorsAssessor={errorsAssessor.B6}
        qErrorsCandidate={errorsCandidate.B6}
      />
      {environment === "assessor" ? (
        <QuestionAssessmentContainer
          onSubmit={handleSubmitB((data) => saveFormData())}
          question={"B6"}
          watch={watch}
          scoreType={"Assessor scored"}
          scoreExplanation={""}
          register={registerB}
          qErrorsAssessor={{
            score: errorsAssessor.B6_score,
            comment: errorsAssessor.B6_comment,
          }}
        />
      ) : null}
      <QuestionTextBox
        question={"B7"}
        questionText={"What do you think of competition, in a general sense?"}
        register={registerB}
        qErrors={errors.B7}
        qErrorsAssessor={errorsAssessor.B7}
        qErrorsCandidate={errorsCandidate.B7}
      />
      {environment === "assessor" ? (
        <QuestionAssessmentContainer
          onSubmit={handleSubmitB((data) => saveFormData())}
          question={"B7"}
          watch={watch}
          scoreType={"Assessor scored"}
          scoreExplanation={""}
          register={registerB}
          qErrorsAssessor={{
            score: errorsAssessor.B7_score,
            comment: errorsAssessor.B7_comment,
          }}
        />
      ) : null}
      <QuestionTextBox
        question={"B8"}
        questionText={
          "What do you think are the key ingredients to becoming successful at something?"
        }
        register={registerB}
        qErrors={errors.B8}
        qErrorsAssessor={errorsAssessor.B8}
        qErrorsCandidate={errorsCandidate.B8}
      />
      {environment === "assessor" ? (
        <QuestionAssessmentContainer
          onSubmit={handleSubmitB((data) => saveFormData())}
          question={"B8"}
          watch={watch}
          scoreType={"Assessor scored"}
          scoreExplanation={""}
          register={registerB}
          qErrorsAssessor={{
            score: errorsAssessor.B8_score,
            comment: errorsAssessor.B8_comment,
          }}
        />
      ) : null}
      <QuestionShortText
        question={"B9a"}
        questionText={'How would you define a "genius"?'}
        register={registerB}
        qErrors={errors.B9a}
        qErrorsAssessor={errorsAssessor.B9a}
        qErrorsCandidate={errorsCandidate.B9a}
      />
      {environment === "assessor" ? (
        <QuestionAssessmentContainer
          onSubmit={handleSubmitB((data) => saveFormData())}
          question={"B9a"}
          watch={watch}
          scoreType={"Assessor scored"}
          scoreExplanation={""}
          register={registerB}
          qErrorsAssessor={{
            score: errorsAssessor.B9a_score,
            comment: errorsAssessor.B9a_comment,
          }}
        />
      ) : null}
      <QuestionShortText
        question={"B9b"}
        questionText={"How does a person become a genius?"}
        register={registerB}
        qErrors={errors.B9b}
        qErrorsAssessor={errorsAssessor.B9b}
        qErrorsCandidate={errorsCandidate.B9b}
      />
      {environment === "assessor" ? (
        <QuestionAssessmentContainer
          onSubmit={handleSubmitB((data) => saveFormData())}
          question={"B9b"}
          watch={watch}
          scoreType={"Assessor scored"}
          scoreExplanation={""}
          register={registerB}
          qErrorsAssessor={{
            score: errorsAssessor.B9b_score,
            comment: errorsAssessor.B9b_comment,
          }}
        />
      ) : null}
      <QuestionTextBox
        question={"B10"}
        questionText={
          "Please think of a big disappointment in your life. How did you respond to it? (You can choose whether to say what the diasappoint was or not. The main point is to explain how you responded to it.)"
        }
        register={registerB}
        qErrors={errors.B10}
        qErrorsAssessor={errorsAssessor.B10}
        qErrorsCandidate={errorsCandidate.B10}
      />
      {environment === "assessor" ? (
        <QuestionAssessmentContainer
          onSubmit={handleSubmitB((data) => saveFormData())}
          question={"B10"}
          watch={watch}
          scoreType={"Assessor scored"}
          scoreExplanation={""}
          register={registerB}
          qErrorsAssessor={{
            score: errorsAssessor.B10_score,
            comment: errorsAssessor.B10_comment,
          }}
        />
      ) : null}
      <QuestionTextBox
        question={"B11"}
        questionText={'What does the word "hope" mean to you?'}
        register={registerB}
        qErrors={errors.B11}
        qErrorsAssessor={errorsAssessor.B11}
        qErrorsCandidate={errorsCandidate.B11}
      />
      {environment === "assessor" ? (
        <QuestionAssessmentContainer
          onSubmit={handleSubmitB((data) => saveFormData())}
          question={"B11"}
          watch={watch}
          scoreType={"Assessor scored"}
          scoreExplanation={""}
          register={registerB}
          qErrorsAssessor={{
            score: errorsAssessor.B11_score,
            comment: errorsAssessor.B11_comment,
          }}
        />
      ) : null}
      <QuestionTextBox
        question={"B12a"}
        questionText={
          "Can you imagine what it would feel like to be hopeless? If you can, how do you imagine it would feel?"
        }
        register={registerB}
        qErrors={errors.B12a}
        qErrorsAssessor={errorsAssessor.B12a}
        qErrorsCandidate={errorsCandidate.B12a}
      />
      {environment === "assessor" ? (
        <QuestionAssessmentContainer
          onSubmit={handleSubmitB((data) => saveFormData())}
          question={"B12a"}
          watch={watch}
          scoreType={"Assessor scored"}
          scoreExplanation={""}
          register={registerB}
          qErrorsAssessor={{
            score: errorsAssessor.B12a_score,
            comment: errorsAssessor.B12a_comment,
          }}
        />
      ) : null}
      <QuestionTextBox
        question={"B12b"}
        questionText={
          "If you answered Yes to the previous question - what would you do, or what did you do, if/when you began feeling like that?"
        }
        register={registerB}
        qErrors={errors.B12b}
        qErrorsAssessor={errorsAssessor.B12b}
        qErrorsCandidate={errorsCandidate.B12b}
      />
      {environment === "assessor" ? (
        <QuestionAssessmentContainer
          onSubmit={handleSubmitB((data) => saveFormData())}
          question={"B12b"}
          watch={watch}
          scoreType={"Assessor scored"}
          scoreExplanation={""}
          register={registerB}
          qErrorsAssessor={{
            score: errorsAssessor.B12b_score,
            comment: errorsAssessor.B12b_comment,
          }}
        />
      ) : null}
      {formDataBeingSent ? (
        <button type="button" className="cta cta--accent--clicked">
          Review
        </button>
      ) : null}
      {environment === "assessor" && !formDataBeingSent ? (
        <button
          type="button"
          className="cta cta--accent"
          onClick={handleValidateSectionAssessor}
        >
          Review
        </button>
      ) : null}
      {environment === "candidate" && !formDataBeingSent ? (
        <button
          type="button"
          className="cta cta--accent"
          onClick={handleValidateSectionCandidate}
        >
          Review
        </button>
      ) : null}
    </form>
  );
}

export default withContext(SectionB);
