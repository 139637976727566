import Joi from "joi";

const schemaFormAccessForm = Joi.object({
  accessCode: Joi.string()
    .trim()
    .min(6)
    .max(6)
    // .pattern(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/)
    .required()
    .messages({
      "string.pattern.base": "access code is not the correct format",
      "string.base": "please enter a valid access code",
      "string.empty": "please enter a access code",
    }),
});

export default schemaFormAccessForm;
