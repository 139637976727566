import { ServerError, ClientError } from "errors";
import {
  validateToken,
  validateCallback,
  validateEmail,
} from "../../validators";

const API_URL = process.env.REACT_APP_API_URL;

function getFormResponse(sStorage, route, endpoint, callback) {
  // TODO: validate route and endpoint
  if (endpoint === "candidate") {
    validateToken(sStorage.candidate_token);
  } else if (endpoint === "assessor") {
    validateToken(sStorage.token);
  } else if (endpoint === "candidateassessor") {
    validateToken(sStorage.token);
  }

  validateCallback(callback);

  const xhr = new XMLHttpRequest();

  xhr.onload = function () {
    const status = xhr.status;

    if (status >= 500) {
      const message = JSON.parse(xhr.response).error;
      callback(new ServerError(`error ${status}: ${message}`));
    } else if (status >= 400) {
      const message = JSON.parse(xhr.response).error;
      callback(new ClientError(`error ${status}: ${message}`));
    } else if (status === 200) {
      const response = xhr.responseText;

      const responseParsed = JSON.parse(response);
      callback(null, responseParsed);
    }
  };

  if (endpoint === "candidate") {
    xhr.open("GET", `${API_URL}/castform/scoreresponse/candidate/${route}`);
  } else if (endpoint === "assessor") {
    xhr.open("GET", `${API_URL}/castform/scoreresponse/${route}`);
  } else if (endpoint === "candidateassessor") {
    xhr.open("GET", `${API_URL}/castform/scoreresponse/${route}`);
  }

  xhr.setRequestHeader("Content-type", "application/json");

  if (endpoint === "candidate") {
    xhr.setRequestHeader("Authorization", `Bearer ${sStorage.candidate_token}`);
  } else if (endpoint === "assessor") {
    xhr.setRequestHeader("Authorization", `Bearer ${sStorage.token}`);
  } else if (endpoint === "candidateassessor") {
    xhr.setRequestHeader("Authorization", `Bearer ${sStorage.token}`);
  }

  xhr.send();
}

export default getFormResponse;
