// ========== Imports ========== //

import "./LoginPage.css";

import { useEffect } from "react";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
// to install $npm install @hookform/resolvers joi
import { joiResolver } from "@hookform/resolvers/joi";
import { schemaFormAccessForm } from "../validators";
import { authenticateAccessCode } from "../logic";
import { ServerError } from "errors";
import withContext from "../utils/withContext";
import Loggito from "../utils/Loggito";

// ========== Component ========== //

function FormAccessPage({
  handleNavigationToRegister,
  handleNavigationToForm,
  context: { refreshTokenCount },
}) {
  // ========== Hook consts ========== //

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: joiResolver(schemaFormAccessForm) });

  // ========== other consts ========== //

  const logger = new Loggito("FormAccessPage");

  // ========== useEffects ========== //

  useEffect(() => {
    logger.info("componentDidMount");
  }, []);

  // ========== Functions ========== //

  const handleLinkClick = (event) => {
    event.preventDefault();

    handleNavigationToRegister();
  };

  // ========== Function to submit login details and log user in while saving token ========== //
  const handleFormSubmit = (data) => {
    try {
      authenticateAccessCode(
        // data.email.trim(),
        data.accessCode.trim(),
        (error, token, refresh_token, route) => {
          if (error) {
            if (error instanceof ServerError) {
              toast.error(error.message);
              logger.error(error.message);
            } else {
              toast.warn(error.message);
              logger.warn(error.message);
            }
            return;
          }

          logger.debug("user logged in");

          sessionStorage.candidate_token = token;
          sessionStorage.candidate_refresh_token = refresh_token;

          refreshTokenCount("start");

          handleNavigationToForm(route);
        }
      );
    } catch (error) {
      logger.warn(error.message);
      toast.warn(error.message);
    }
  };

  // ========== jsx ========== //

  return (
    <main className="login-page">
      <div className="login-elements">
        <form
          onSubmit={handleSubmit((data) => handleFormSubmit(data))}
          className="login-form p--m"
          noValidate={true}
        >
          {/* <label htmlFor="email" className="label p--m">
            Email
          </label>
          <input
            type="email"
            placeholder="Email"
            name="email"
            className="p--m"
            {...register("email", {})}
          />
          <p className="input__error-message p--m">{errors.email?.message}</p> */}
          <label htmlFor="password" className="label p--m">
            Access Code
          </label>
          <input
            type="text"
            placeholder="Access code"
            name="accessCode"
            className="p--m"
            {...register("accessCode", {})}
          />
          <p className="input__error-message p--m">
            {errors.accessCode?.message}
          </p>

          <button
            // href="home.html"
            type="submit"
            className="cta cta--primary cta--submit"
          >
            Access Form
          </button>
        </form>
        {/*  <Link to={"/admin/register"} className="cta cta--outline">
          Not registered?
        </Link>
        <Link to={"/admin/forgot-password"} className="cta cta--outline">
          Forgot password?
        </Link> */}
      </div>
    </main>
  );
}

export default withContext(FormAccessPage);
