import "./MyCarouselAlignLeft.css";

import { useState, useEffect, useRef, useLayoutEffect } from "react";
import Loggito from "../../../utils/Loggito";
import { toast } from "react-toastify";
import { getNewsClient } from "../../../logic";
import { ServerError } from "errors";
import { Link } from "react-router-dom";
import { useSwipeable } from "react-swipeable";

const MyCarousel = () => {
  const [newsData, setNewsData] = useState([]);
  const [activeIndex, setActiveIndex] = useState(2);
  const [paused, setPaused] = useState(false);

  const logger = new Loggito("MyCarouselEditorsPicks");

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      if (activeIndex === newsData.length - 2) setActiveIndex(2);
      else setActiveIndex(activeIndex + 1);
    },
    onSwipedRight: () => {
      if (activeIndex === 1) setActiveIndex(newsData.length - 3);
      else setActiveIndex(activeIndex - 1);
    },
  });

  useEffect(() => {
    const interval = setInterval(() => {
      if (!paused) {
        if (activeIndex === newsData.length - 2) setActiveIndex(2);
        else setActiveIndex(activeIndex + 1);
      }
    }, 3000);

    return () => {
      if (interval) clearInterval(interval);
    };
  });

  useEffect(() => {
    loadNews();
  }, []);

  const loadNews = () => {
    try {
      return getNewsClient((error, newsArray) => {
        if (error) {
          if (error instanceof ServerError) {
            toast.error(error.message);
            logger.error(error.message);
          } else {
            toast.warn(error.message);
            logger.warn(error.message);
          }
          return;
        }

        // TODO: filter this server side
        const editorsPicks = newsArray.filter(
          (article) => article.editorsPick === true
        );

        if (editorsPicks.length > 2) {
          const infiniteArray = [
            editorsPicks[editorsPicks.length - 2],
            editorsPicks[editorsPicks.length - 1],
            ...editorsPicks,
            editorsPicks[0],
          ];

          setNewsData(infiniteArray);
        } else setNewsData(editorsPicks);
      });
    } catch (error) {
      toast.warn(error.message);
      logger.warn(error.message);
    }
  };

  const moveForwards = () => {
    if (activeIndex === newsData.length - 2) setActiveIndex(2);
    else setActiveIndex(activeIndex + 1);
  };

  const moveBackwards = () => {
    if (activeIndex === 1) setActiveIndex(newsData.length - 3);
    else setActiveIndex(activeIndex - 1);
  };

  if (newsData.length > 2) {
    return (
      <div
        className="carousel-container"
        {...handlers}
        onMouseEnter={() => setPaused(true)}
        onMouseLeave={() => setPaused(false)}
      >
        <Carousel
          activeIndex={activeIndex}
          setActiveIndex={setActiveIndex}
          className="carousel-component"
        >
          {newsData.map((card, i) => {
            return (
              <CarouselCard
                key={`${card.id}${Math.floor(
                  Math.random() * 1000000000000
                )}${Date.now()}`}
                active={activeIndex === i}
              >
                <div className="carousel-card-content">
                  {/* Adding text to the card breaks the css and grows outside of limits for more than 4 cards */}
                  {/* <div className="carousel-card-title">{card.title}</div> */}
                  {/* Using image works the same as using div with background set as image */}
                  <Link to={`/news/${card.route}`}>
                    <div
                      className="carousel-card-content card-image"
                      style={{
                        backgroundImage: `url(${card.mainImage.fileUrl})`,
                      }}
                    ></div>
                  </Link>
                  <Link
                    to={`/news/${card.route}`}
                    className="card-title link p--m"
                  >
                    {card.title.length < 95
                      ? card.title
                      : card.title.substring(0, 95) + "..."}
                  </Link>
                </div>
              </CarouselCard>
            );
          })}
        </Carousel>
        <div className="button-group">
          <button
            type="button"
            disabled={activeIndex === 0}
            onClick={moveBackwards}
          >
            Prev
          </button>
          <button
            type="button"
            disabled={activeIndex === newsData.length - 1}
            onClick={moveForwards}
          >
            Next
          </button>
        </div>
        <div className="line--secondary"></div>
      </div>
    );
  } else return null;
};

export default MyCarousel;

const Carousel = ({ activeIndex, setActiveIndex, children }) => {
  const carouselRef = useRef(null);
  const [carouselTranslate, setCarouselTranslate] = useState(null);

  const [size, setSize] = useState([0, 0]);

  function useWindowSize() {
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener("resize", updateSize);
      updateSize();
      return () => window.removeEventListener("resize", updateSize);
    }, []);
    return size;
  }
  useWindowSize();

  useEffect(() => {
    // offsetWidth is the viewable area of the element, all except margin. If we have 4 elements in the coarousel we divide by 8 to move the first item left by half
    // /8 for 4 elements in carousel
    // const initialTranslateVal = carouselRef.current.offsetWidth / 8;
    // for 3 elements in carousel

    // const initialTranslateVal = carouselRef.current.offsetWidth / (2 / (1 / 3));
    let initialTranslateVal;
    if (window.screen.width >= 1024) {
      initialTranslateVal = carouselRef.current.offsetWidth / (2 / (1 / 3));
    } else if (window.screen.width >= 700) {
      initialTranslateVal = carouselRef.current.offsetWidth / (2 / (1 / 2));
    } else {
      initialTranslateVal = carouselRef.current.offsetWidth / 2;
    }
    // the offsetWidth is then multiplied by two to get the original element width
    const diffAmount = initialTranslateVal * 2;

    let translate;

    if (window.screen.width >= 700) {
      translate =
        activeIndex === 0
          ? initialTranslateVal + initialTranslateVal / 1.2
          : initialTranslateVal -
            activeIndex * diffAmount +
            initialTranslateVal / 1.2;
    } else {
      translate =
        activeIndex === 0
          ? initialTranslateVal + initialTranslateVal / 1.2
          : initialTranslateVal -
            activeIndex * diffAmount +
            initialTranslateVal / 1;
    }

    setCarouselTranslate(translate);
  }, [activeIndex, size]);

  return (
    <>
      <div
        className="carousel"
        ref={carouselRef}
        style={{ transform: `translateX(${carouselTranslate}px)` }}
      >
        {children}
      </div>
      <div className="dots">
        {children.slice(1, children.length - 1).map((child, i) => (
          <button
            key={`${i}${Math.floor(
              Math.random() * 1000000000000
            )}${Date.now()}`}
            className={`dot ${activeIndex === i + 1 ? "active" : ""}`}
            onClick={() => setActiveIndex(i)}
          />
        ))}
      </div>
    </>
  );
};

const CarouselCard = ({ active, children }) => {
  return (
    <div className={`carousel-card ${active ? "active" : ""}`}>{children}</div>
  );
};
