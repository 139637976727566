// ========== imports ========== //
import "./CASTForm.css";

import React, { useRef, useState, useEffect } from "react";
import {
  Section1,
  Section2,
  SectionA,
  SectionB,
  SectionC,
  SectionD,
  SectionE,
  SectionF,
  AssessorNav,
  FormAnalysisTable,
} from "./FormSections";

import { useParams, useNavigate } from "react-router-dom";

import useAnalyticsPageViewSenderGA4 from "../../../utils/useAnalyticsPageViewSenderGA4";

import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";

import Loggito from "../../../utils/Loggito";
import withContext from "../../../utils/withContext";

import { updateCastFormResponse, getFormResponse } from "../../../logic";

import { toast } from "react-toastify";

import { ServerError } from "errors";

import { useForm } from "react-hook-form";
// ========== Page ========== //

const SECRET_KEY = process.env.REACT_APP_RECAPTCHA_SECRET_KEY;
const SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

const SERVICE_ID = process.env.REACT_APP_SERVICE_ID;
const TEMPLATE_ID = process.env.REACT_APP_TEMPLATE_ID;
const PUBLIC_KEY = process.env.REACT_APP_PUBLIC_KEY;

function AssessorForm() {
  // ========== Hook consts ========== //
  const [formDataBeingSent, setFormDataBeingSent] = useState(false);

  const [existingValues, setExistingValues] = useState({});

  // const form = useRef();

  const gaPageViewSender = useAnalyticsPageViewSenderGA4();

  useEffect(() => {
    gaPageViewSender("CAST Form Dev");
  }, []);

  useEffect(() => {
    getFormResponseData();
  }, []);

  // const params = useParams();

  const params = useParams();
  const navigate = useNavigate();
  let route = params.route;
  // To try to focus() on the question
  /*   let questionFocus = params.question;

  if (questionFocus) {
    debugger;
    const questionComponent = document.getElementById(
      questionFocus.slice(1).slice(0, -5)
    );

    if (questionComponent) questionComponent.focus();
  } */

  const environment = params.environment;
  console.log(environment);

  const getFormResponseData = () => {
    console.log(route);
    try {
      getFormResponse(
        sessionStorage,
        route,
        "assessor",
        (error, formDataRetrieved) => {
          if (error) {
            if (error instanceof ServerError) {
              navigate("/admin/castresponsestable");
              toast.error(error.message);
              logger.error(error.message);
            } else {
              navigate("/admin/castresponsestable");
              toast.warn(error.message);
              logger.warn(error.message);
              if (error.message === "session timed out, please log in again") {
                delete sessionStorage.token;
                window.location.reload(false);
              }
            }
            return;
          }
          if (formDataRetrieved.detailsTypeOfResponse === "Candidate") {
            // failsafe added for trying to edit a candidate form by changing the assessor URL
            navigate("/admin/castresponsestable");
            return;
          }
          setExistingValues(formDataRetrieved);
        }
      );
    } catch (error) {
      navigate("/admin/castresponsestable");
      toast.warn(error.message);
      logger.warn(error.message);
    }
  };

  // ========== other consts ========== //

  const logger = new Loggito("CAST Form Dev");

  // ========== useEffects ========== //

  // ========== jsx ========== //

  return (
    <div className="cast-form-background">
      <div className="section cast-form-container">
        <Section1 environment={environment} existingValues={existingValues} />

        <SectionA environment={environment} existingValues={existingValues} />

        <SectionB environment={environment} existingValues={existingValues} />
        <SectionC environment={environment} existingValues={existingValues} />
        <SectionD environment={environment} existingValues={existingValues} />
        <SectionE environment={environment} existingValues={existingValues} />
        <SectionF environment={environment} existingValues={existingValues} />

        <FormAnalysisTable
          environment={environment}
          existingValues={existingValues}
        />
        <AssessorNav />
      </div>
    </div>
  );
}

export default withContext(AssessorForm);
