import {
  validateEmail,
  validatePassword,
  validateAccessCode,
  validateCallback,
} from "../../validators";
import { AuthError, ClientError, ServerError, UnknownError } from "errors";

const API_URL = process.env.REACT_APP_API_URL;

/**
 * Checks user credentials against database
 *
 * @param {string} email The user email
 * @param {string} password The user password
 * @param {function} callback The function expression that provides a result
 *
 * @throws {Error | TypeError} On invalid inputs
 */

function authenticateAccessCode(accessCode, callback) {
  validateAccessCode(accessCode);
  validateCallback(callback);

  const xhr = new XMLHttpRequest();

  //response

  xhr.onload = function () {
    const status = xhr.status;

    const json = xhr.responseText;

    const { error, token, refresh_token, route } = JSON.parse(json);

    if (status >= 500) {
      callback(new ServerError(`error ${status}: ${error}`));
    } else if (status === 401) {
      callback(new AuthError(`error ${status}: ${error}`));
    } else if (status >= 400) {
      callback(new ClientError(`error ${status}: ${error}`));
    } else if (status === 200) {
      callback(null, token, refresh_token, route);
    } else callback(new UnknownError(`error ${status}: ${error}`));
  };

  xhr.onerror = function () {
    callback(new ServerError("connection failed"));
  };

  xhr.open("POST", `${API_URL}/forms/auth`);

  xhr.setRequestHeader("Content-type", "application/json");

  xhr.send(`{ "detailsAccessCode": "${accessCode}"}`);
}

export default authenticateAccessCode;
