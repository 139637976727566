import Joi from "joi";

const schemaFormSection1 = Joi.object({
  detailsFullName: Joi.string().trim().max(100).allow("").allow(null).messages({
    "string.base": "please write your response to a maximum of 2000 characters",
    "string.empty":
      "please write your response to a maximum of 2000 characters",
  }),
  detailsCandidateEmail: Joi.string()
    .trim()
    .pattern(
      /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
    )
    .max(254)
    .allow("")
    .allow(null)
    .messages({
      "string.pattern.base": `candidate email is not in the correct format`,
      "string.base": `candidate email is not the correct type`,
      "string.empty": `candidate email cannot be empty`,
    }),
  detailsOrganisation: Joi.string()
    .trim()
    .max(100)
    .allow("")
    .allow(null)
    .messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
    }),
  detailsRole: Joi.string().trim().max(100).allow("").allow(null).messages({
    "string.base": "please write your response to a maximum of 2000 characters",
    "string.empty":
      "please write your response to a maximum of 2000 characters",
  }),
  detailsGender: Joi.string().trim().max(100).allow("").allow(null).messages({
    "string.base": "please write your response to a maximum of 2000 characters",
    "string.empty":
      "please write your response to a maximum of 2000 characters",
  }),
  detailsAssessor: Joi.string().trim().max(100).allow("").allow(null).messages({
    "string.base": "please write your response to a maximum of 2000 characters",
    "string.empty":
      "please write your response to a maximum of 2000 characters",
  }),
  detailsDateAssessed: Joi.string()
    .trim()
    .max(100)
    .allow("")
    .allow(null)
    .messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
    }),
});

export default schemaFormSection1;
