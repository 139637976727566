// ========== Imports ========== //

import "./FormAnalysisTable.css";

import React, { useState, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import Loggito from "../../../../utils/Loggito";
import { toast } from "react-toastify";
import { ServerError } from "errors";
import { Spinner } from "../../../Common";
import { getFormResponse } from "../../../../logic";

// ========== Component ========== //

// This logger created outside of component so that it is also available to the helper printEvents
const logger = new Loggito("FormsTable");

// Receives the eventId and return the table

function FormAnalysisTable({ environment }) {
  // ========== Hook consts ========== //

  const [responsesData, setResponsesData] = useState();
  const [tableData, setTableData] = useState(null);

  /*   const [sectionATotal, setSectionATotal] = useState({});
  const [sectionBTotal, setSectionBTotal] = useState({});
  const [sectionCTotal, setSectionCTotal] = useState({});
  const [sectionDTotal, setSectionDTotal] = useState({});
  const [sectionETotal, setSectionETotal] = useState({});
  const [sectionFTotal, setSectionFTotal] = useState({}); */
  /* 
  useEffect(() => {
    calculateTotals();
  }, [responsesData]); */

  // ========== other consts ========== //

  const calculateTotals = (formDataRetrieved) => {
    const calculatedScores = {
      sectionTotals: {
        sectionATotal: 0,
        sectionBTotal: 0,
        sectionCTotal: 0,
        sectionDTotal: 0,
        sectionETotal: 0,
        sectionFTotal: 0,
      },
      sectionPercentageScores: {
        sectionAPercentageScore: 0,
        sectionBPercentageScore: 0,
        sectionCPercentageScore: 0,
        sectionDPercentageScore: 0,
        sectionEPercentageScore: 0,
        sectionFPercentageScore: 0,
      },
      sectionAdjustedScores: {
        sectionAAdjustedScore: 0,
        sectionBAdjustedScore: 0,
        sectionCAdjustedScore: 0,
        sectionDAdjustedScore: 0,
        sectionEAdjustedScore: 0,
        sectionFAdjustedScore: 0,
      },
      totalAdjustedScore: 0,
    };

    // TODO: do this automatically - if the string is a number or integer parseInt and change value in object
    calculatedScores.sectionTotals.sectionATotal =
      parseInt(formDataRetrieved.A1a.response) +
      parseInt(formDataRetrieved.A1b.score) +
      parseInt(formDataRetrieved.A2.score) +
      parseInt(formDataRetrieved.A3.score) +
      parseInt(formDataRetrieved.A4.score) +
      parseInt(formDataRetrieved.A5.score) +
      parseInt(formDataRetrieved.A6.score);

    calculatedScores.sectionPercentageScores.sectionAPercentageScore =
      calculatedScores.sectionTotals.sectionATotal / 21;
    calculatedScores.sectionAdjustedScores.sectionAAdjustedScore =
      calculatedScores.sectionPercentageScores.sectionAPercentageScore * 0.175;

    calculatedScores.sectionTotals.sectionBTotal =
      parseInt(formDataRetrieved.B1a.response) +
      parseInt(formDataRetrieved.B1b.score) +
      parseInt(formDataRetrieved.B1c.score) +
      parseInt(formDataRetrieved.B1d.score) +
      parseInt(formDataRetrieved.B1e.score) +
      parseInt(formDataRetrieved.B2.score) +
      parseInt(formDataRetrieved.B3.score) +
      // parseInt(formDataRetrieved.B4a.response) +
      parseInt(formDataRetrieved.B4b.score) +
      parseInt(formDataRetrieved.B5.score) +
      parseInt(formDataRetrieved.B5.score) +
      parseInt(formDataRetrieved.B6.score) +
      parseInt(formDataRetrieved.B7.score) +
      parseInt(formDataRetrieved.B8.score) +
      parseInt(formDataRetrieved.B9a.score) +
      parseInt(formDataRetrieved.B9b.score) +
      parseInt(formDataRetrieved.B10.score) +
      parseInt(formDataRetrieved.B11.score) +
      parseInt(formDataRetrieved.B12a.score) +
      parseInt(formDataRetrieved.B12b.score);

    calculatedScores.sectionPercentageScores.sectionBPercentageScore =
      calculatedScores.sectionTotals.sectionBTotal / 42;
    calculatedScores.sectionAdjustedScores.sectionBAdjustedScore =
      calculatedScores.sectionPercentageScores.sectionBPercentageScore * 0.175;

    calculatedScores.sectionTotals.sectionCTotal =
      parseInt(formDataRetrieved.C1.response) +
      parseInt(formDataRetrieved.C2.response) +
      parseInt(formDataRetrieved.C3.response) +
      parseInt(formDataRetrieved.C4.response) +
      parseInt(formDataRetrieved.C5.response) +
      parseInt(formDataRetrieved.C6.response) +
      parseInt(formDataRetrieved.C7.response) +
      parseInt(formDataRetrieved.C8.response) +
      parseInt(formDataRetrieved.C9.response) +
      parseInt(formDataRetrieved.C10.response) +
      parseInt(formDataRetrieved.C11.response) +
      parseInt(formDataRetrieved.C12.response);

    calculatedScores.sectionPercentageScores.sectionCPercentageScore =
      calculatedScores.sectionTotals.sectionCTotal / 36;
    calculatedScores.sectionAdjustedScores.sectionCAdjustedScore =
      calculatedScores.sectionPercentageScores.sectionCPercentageScore * 0.175;

    calculatedScores.sectionTotals.sectionDTotal =
      parseInt(formDataRetrieved.D1.response) +
      parseInt(formDataRetrieved.D2.response) +
      parseInt(formDataRetrieved.D3.response) +
      parseInt(formDataRetrieved.D4.response) +
      parseInt(formDataRetrieved.D5.response) +
      parseInt(formDataRetrieved.D6.score) +
      // parseInt(formDataRetrieved.D7a.response) +
      parseInt(formDataRetrieved.D7b.score) +
      parseInt(formDataRetrieved.D8.score) +
      parseInt(formDataRetrieved.D9.score) +
      parseInt(formDataRetrieved.D10a.score) +
      parseInt(formDataRetrieved.D10b.score);

    calculatedScores.sectionPercentageScores.sectionDPercentageScore =
      calculatedScores.sectionTotals.sectionDTotal / 30;
    calculatedScores.sectionAdjustedScores.sectionDAdjustedScore =
      calculatedScores.sectionPercentageScores.sectionDPercentageScore * 0.175;

    calculatedScores.sectionTotals.sectionETotal =
      parseInt(formDataRetrieved.E1.response) +
      parseInt(formDataRetrieved.E2.response) +
      parseInt(formDataRetrieved.E3.response) +
      parseInt(formDataRetrieved.E4.response) +
      parseInt(formDataRetrieved.E5.response) +
      parseInt(formDataRetrieved.E6.response) +
      parseInt(formDataRetrieved.E7.response) +
      parseInt(formDataRetrieved.E8.response) +
      parseInt(formDataRetrieved.E9.response) +
      parseInt(formDataRetrieved.E10.response) +
      parseInt(formDataRetrieved.E11.response) +
      parseInt(formDataRetrieved.E12.response) +
      parseInt(formDataRetrieved.E13.response) +
      parseInt(formDataRetrieved.E14.response) +
      parseInt(formDataRetrieved.E15.response) +
      parseInt(formDataRetrieved.E16.response);

    calculatedScores.sectionPercentageScores.sectionEPercentageScore =
      calculatedScores.sectionTotals.sectionETotal / 48;
    calculatedScores.sectionAdjustedScores.sectionEAdjustedScore =
      calculatedScores.sectionPercentageScores.sectionEPercentageScore * 0.1;

    calculatedScores.sectionTotals.sectionFTotal =
      parseInt(formDataRetrieved.F1.response) +
      parseInt(formDataRetrieved.F2.response) +
      parseInt(formDataRetrieved.F3.response) +
      parseInt(formDataRetrieved.F4.response) +
      parseInt(formDataRetrieved.F5.response) +
      parseInt(formDataRetrieved.F6.response) +
      parseInt(formDataRetrieved.F7.response) +
      parseInt(formDataRetrieved.F8.response) +
      parseInt(formDataRetrieved.F9.response) +
      parseInt(formDataRetrieved.F10.response) +
      parseInt(formDataRetrieved.F11.response) +
      parseInt(formDataRetrieved.F12.response) +
      parseInt(formDataRetrieved.F13.response) +
      parseInt(formDataRetrieved.F14.response) +
      parseInt(formDataRetrieved.F15.response) +
      parseInt(formDataRetrieved.F16.response);

    calculatedScores.sectionPercentageScores.sectionFPercentageScore =
      calculatedScores.sectionTotals.sectionFTotal / 48;
    calculatedScores.sectionAdjustedScores.sectionFAdjustedScore =
      calculatedScores.sectionPercentageScores.sectionFPercentageScore * 0.2;

    calculatedScores.totalAdjustedScore =
      calculatedScores.sectionAdjustedScores.sectionBAdjustedScore +
      calculatedScores.sectionAdjustedScores.sectionCAdjustedScore +
      calculatedScores.sectionAdjustedScores.sectionDAdjustedScore +
      calculatedScores.sectionAdjustedScores.sectionEAdjustedScore +
      calculatedScores.sectionAdjustedScores.sectionFAdjustedScore;

    generateTableData(calculatedScores);
  };

  // ========== useEffects ========== //

  useEffect(() => {
    getFormResponseData();
  }, []);

  const params = useParams();
  let route = params.route;

  const getFormResponseData = () => {
    console.log(route);
    try {
      getFormResponse(
        sessionStorage,
        route,
        "assessor",
        (error, formDataRetrieved) => {
          if (error) {
            if (error instanceof ServerError) {
              //   navigate("/admin/castresponsestable");
              toast.error(error.message);
              logger.error(error.message);
            } else {
              //   navigate("/admin/castresponsestable");
              toast.warn(error.message);
              logger.warn(error.message);
              if (error.message === "session timed out, please log in again") {
                delete sessionStorage.token;
                window.location.reload(false);
              }
            }
            return;
          }
          calculateTotals(formDataRetrieved);
          setResponsesData(formDataRetrieved);
        }
      );
    } catch (error) {
      //   navigate("/admin/castresponsestable");
      toast.warn(error.message);
      logger.warn(error.message);
    }
  };

  const generateTableData = (calculatedScores) => {
    const analysedResults = [
      {
        section: "Section A",
        sectionScore: calculatedScores.sectionTotals.sectionATotal,
        possibleScore: "21",
        percentageScore:
          calculatedScores.sectionPercentageScores.sectionAPercentageScore *
          100,
        adjustWith: 0.175,
        adjustedPercentageScore:
          calculatedScores.sectionAdjustedScores.sectionAAdjustedScore * 100,
      },
      {
        section: "Section B",
        sectionScore: calculatedScores.sectionTotals.sectionBTotal,
        possibleScore: "",
        percentageScore:
          calculatedScores.sectionPercentageScores.sectionBPercentageScore *
          100,
        adjustWith: 0.175,
        adjustedPercentageScore:
          calculatedScores.sectionAdjustedScores.sectionBAdjustedScore * 100,
      },
      {
        section: "Section C",
        sectionScore: calculatedScores.sectionTotals.sectionCTotal,
        possibleScore: "",
        percentageScore:
          calculatedScores.sectionPercentageScores.sectionCPercentageScore *
          100,
        adjustWith: 0.175,
        adjustedPercentageScore:
          calculatedScores.sectionAdjustedScores.sectionCAdjustedScore * 100,
      },
      {
        section: "Section D",
        sectionScore: calculatedScores.sectionTotals.sectionDTotal,
        possibleScore: "",
        percentageScore:
          calculatedScores.sectionPercentageScores.sectionDPercentageScore *
          100,
        adjustWith: 0.175,
        adjustedPercentageScore:
          calculatedScores.sectionAdjustedScores.sectionDAdjustedScore * 100,
      },
      {
        section: "Section E",
        sectionScore: calculatedScores.sectionTotals.sectionETotal,
        possibleScore: "",
        percentageScore:
          calculatedScores.sectionPercentageScores.sectionEPercentageScore *
          100,
        adjustWith: 0.2,
        adjustedPercentageScore:
          calculatedScores.sectionAdjustedScores.sectionEAdjustedScore * 100,
      },
      {
        section: "Section F",
        sectionScore: calculatedScores.sectionTotals.sectionFTotal,
        possibleScore: "",
        percentageScore:
          calculatedScores.sectionPercentageScores.sectionFPercentageScore *
          100,
        adjustWith: 0.1,
        adjustedPercentageScore:
          calculatedScores.sectionAdjustedScores.sectionFAdjustedScore * 100,
      },
    ];

    setTableData(analysedResults.map(rowTable));
    // setTableData(...tableData, analysedResults.map(rowTable));
  };

  function rowTable(formSection, key) {
    // ========== JSX ========== //
    return (
      <tr key={formSection.section}>
        <td>{formSection.section}</td>
        <td>{formSection.sectionScore}</td>
        <td>{formSection.possibleScore}</td>
        <td>{formSection.percentageScore.toFixed(2)}%</td>
        <td>{formSection.adjustWith}</td>
        <td>{formSection.adjustedPercentageScore.toFixed(2)}%</td>
      </tr>
    );
  }

  // ========== JSX ========== //

  if (tableData !== "") {
    return (
      <div className="analysis-table">
        <h2 className="h1">Response Analysis</h2>
        <h3 className="h2">Overall Scoring</h3>

        <table className="table table-hover">
          <thead>
            <tr>
              <th>Section</th>
              <th>Section Score</th>
              <th>Possible score</th>
              <th>% Score</th>
              <th>Adjust with</th>
              <th>Adj % Score</th>
            </tr>
          </thead>
          <tbody>
            {tableData ? tableData : null}

            <tr>
              <td>TOTAL SCORE:</td>
              <td></td>
              <td>Possible score</td>
              <td></td>
              <td></td>
              <td>Adj % Score</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  } else {
    return <Spinner />;
  }
}

export default FormAnalysisTable;
