// ========== imports ========== //

// ========== Page ========== //
function QuestionMcqListHorizontalB1({
  question,
  questionText,
  register,
  qErrors,
  qErrorsAssessor,
  qErrorsCandidate,
  section,
  onSubmit,
}) {
  // ========== Hook consts ========== //

  return (
    <div className="cast-form__question-container" id={`${question}_link`}>
      <div className="cast-form__question cast-form__question--radio">
        <p className="p--m cast-form__question__qtext">
          {section !== "1" ? (
            <>
              <b>{question}</b>: {questionText}
            </>
          ) : null}
          {section === "1" ? (
            <>
              <b>{questionText}</b>
            </>
          ) : null}
        </p>
        <div className="radio-options" id="B1a">
          <p className="p--sm">number of jobs</p>
          <div>
            <label htmlFor="B1a_0" className="p--sm">
              0
            </label>
            <input
              type="radio"
              id="B1a_0"
              name={question}
              value={0}
              {...register(question, {
                onChange: (e) => {
                  onSubmit();
                },
              })}
            />
          </div>
          <div>
            <label htmlFor="B1a_1" className="p--sm">
              1
            </label>
            <input
              type="radio"
              id="B1a_1"
              name={question}
              value={1}
              {...register(question, {
                onChange: (e) => {
                  onSubmit();
                },
              })}
            />
          </div>
          <div>
            <label htmlFor="B1a_2" className="p--sm">
              2
            </label>
            <input
              type="radio"
              id="B1a_2"
              name={question}
              value={2}
              {...register(question, {
                onChange: (e) => {
                  onSubmit();
                },
              })}
            />
          </div>
          <div>
            <label htmlFor="B1a_3" className="p--sm">
              3
            </label>
            <input
              type="radio"
              id="B1a_3"
              name={question}
              value={3}
              {...register(question, {
                onChange: (e) => {
                  onSubmit();
                },
              })}
            />
          </div>
          <div>
            <label htmlFor="B1a_4" className="p--sm">
              4
            </label>
            <input
              type="radio"
              id="B1a_4"
              name={question}
              value={4}
              {...register(question, {
                onChange: (e) => {
                  onSubmit();
                },
              })}
            />
          </div>
          <div>
            <label htmlFor="B1a_5" className="p--sm">
              5
            </label>
            <input
              type="radio"
              id="B1a_5"
              name={question}
              value={5}
              {...register(question, {
                onChange: (e) => {
                  onSubmit();
                },
              })}
            />
          </div>
          <div>
            <label htmlFor="B1a_6" className="p--sm">
              6
            </label>
            <input
              type="radio"
              id="B1a_6"
              name={question}
              value={6}
              {...register(question, {
                onChange: (e) => {
                  onSubmit();
                },
              })}
            />
          </div>
          <div>
            <label htmlFor="B1a_7" className="p--sm">
              More than 7
            </label>
            <input
              type="radio"
              id="B1a_7"
              name={question}
              value={7}
              {...register(question, {
                onChange: (e) => {
                  onSubmit();
                },
              })}
            />
          </div>
        </div>
        <div className="cast-form__error-message">
          {qErrors && (
            <p className="input__error-message">{qErrors?.message}</p>
          )}
        </div>
        <div className="cast-form__error-message">
          {qErrorsCandidate && (
            <p className="input__error-message">{qErrorsCandidate}</p>
          )}
        </div>
        <div className="cast-form__error-message">
          {qErrorsAssessor && (
            <p className="input__error-message">{qErrorsAssessor}</p>
          )}
        </div>
      </div>
    </div>
  );
}

export default QuestionMcqListHorizontalB1;
