// ========== Imports ========== //

import "./CASTResponsesTable.css";

import React, { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import Loggito from "../../../utils/Loggito";
import { toast } from "react-toastify";
import { ServerError } from "errors";
import { Spinner } from "../../Common";
import CreateForm from "./CreateForm";
import DeleteForm from "./DeleteForm";
import { getFormResponses } from "../../../logic";

import { Pagination } from "../../Common";

// ========== Component ========== //

// This logger created outside of component so that it is also available to the helper printEvents
const logger = new Loggito("FormsTable");

// Receives the eventId and return the table

function CASTResultsTable() {
  // ========== Hook consts ========== //

  const [responsesData, setResponsesData] = useState("");

  const [currentPage, setCurrentPage] = useState();

  const [dynamicSortKey, setDynamicSortKey] = useState(null);

  const [retrievedResponses, setRetrievedResponses] = useState([]);

  const [pageSize, setPageSize] = useState(10);

  // TODO: a search function based on

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * pageSize;
    const lastPageIndex = firstPageIndex + pageSize;
    return responsesData.slice(firstPageIndex, lastPageIndex);
    // the responseData dependency here is to trigger a change in table view on a change in data by sort (or by removing data on delete or searching)
  }, [currentPage, responsesData, pageSize]);
  // ========== other consts ========== //

  const handleSort = (sortKey) => {
    setDynamicSortKey(dynamicSortKey === sortKey ? "-" + sortKey : sortKey);
  };

  useEffect(() => {
    if (retrievedResponses.length > 0 && dynamicSortKey) {
      setResponsesData(
        retrievedResponses.sort(dynamicSort(dynamicSortKey)).map(rowTable)
      );
      setCurrentPage(1);
    }
  }, [dynamicSortKey]);

  function dynamicSort(property) {
    // Sort with "key" string or "-key"
    let sortOrder = 1;
    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a, b) {
      /* next line works with strings and numbers,
       * and you may want to customize it to your needs
       */
      let result =
        a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
      return result * sortOrder;
    };
  }

  function rowTable(response, index) {
    // ========== JSX ========== //

    return (
      <tr key={response.route}>
        <td>
          {response.detailsFullName.length < 23
            ? response.detailsFullName
            : response.detailsFullName.slice(0, 20) + "..."}
        </td>
        <td>
          {" "}
          {response.detailsOrganisation.length < 23
            ? response.detailsOrganisation
            : response.detailsOrganisation.slice(0, 20) + "..."}
        </td>
        <td>
          {" "}
          {response.detailsCandidateEmail.length < 23
            ? response.detailsCandidateEmail
            : response.detailsCandidateEmail.slice(0, 20) + "..."}
        </td>
        <td>{response.dateCreated.slice(0, 10)}</td>
        <td>{response.dateOfAssessmentCompletion.slice(0, 10)}</td>
        <td>{response.detailsTypeOfResponse}</td>
        <td>
          {" "}
          {response.detailsAssessor.length < 23
            ? response.detailsAssessor
            : response.detailsAssessor.slice(0, 20) + "..."}
        </td>
        <td>{response.detailsAccessCode}</td>
        <td>{response.detailsStatus}</td>
        <td>
          <Link to={`/admin/responses/${response.route}`} className="link">
            Edit Report
          </Link>
        </td>
        <td className="buttons-td">
          {
            <>
              {response.detailsTypeOfResponse === "Candidate" && (
                <Link
                  to={`/admin/candidate-view/candidate/${response.route}`}
                  className="link"
                >
                  View
                </Link>
              )}
              {/* TODO: the edit page of a response can still be accessed manually by editing the URL, this should be made safer */}
              {response.detailsTypeOfResponse === "Interviewer" && (
                <Link
                  to={`/admin/responses/assessor/${response.route}`}
                  className="link"
                >
                   Edit
                </Link>
              )}
              <br />
              <DeleteForm form={response} />
            </>
          }
        </td>
      </tr>
    );
  }
  // ========== useEffects ========== //

  useEffect(() => {
    logger.info("componentDidMount");
    try {
      getFormResponses(sessionStorage.token, (error, responsesArray) => {
        if (error) {
          if (error instanceof ServerError) {
            toast.error(error.message);
            logger.error(error.message);
          } else {
            toast.warn(error.message);
            logger.warn(error.message);
            if (error.message === "session timed out, please log in again") {
              delete sessionStorage.token;
              window.location.reload(false);
            }
          }
          return;
        }
        setRetrievedResponses(responsesArray);
        responsesArray.sort(dynamicSort("-detailsAccessCode"));
        setResponsesData(responsesArray.map(rowTable));
        setCurrentPage(1);
      });
    } catch (error) {
      toast.warn(error.message);
      logger.warn(error.message);
    }
  }, []);

  // ========== JSX ========== //

  if (responsesData !== "") {
    return (
      <div className="responses-table">
        <h1>Manage Responses</h1>
        <CreateForm />
        <form className="page-size-form">
          <label className="p--m" for="cars">
            Page size:&nbsp;
          </label>
          <select
            name="pageSize"
            id="pageSize"
            onChange={(e) => {
              setCurrentPage(1);
              setPageSize(parseInt(e.target.value));
            }}
          >
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={30}>30</option>
            <option value={40}>40</option>
            <option value={50}>50</option>
          </select>
        </form>

        <table className="table table-hover">
          <thead>
            <tr>
              <th
                className="header-sort-button"
                onClick={() => handleSort("detailsFullName")}
              >
                Full Name
              </th>
              <th
                className="header-sort-button"
                onClick={() => handleSort("detailsOrganisation")}
              >
                Organisation
              </th>
              <th
                className="header-sort-button"
                onClick={() => handleSort("detailsCandidateEmail")}
              >
                Email
              </th>
              <th
                className="header-sort-button"
                onClick={() => handleSort("dateCreated")}
              >
                Date created
              </th>
              <th
                className="header-sort-button"
                onClick={() => handleSort("detailsDateAssessed")}
              >
                Date Assessed
              </th>
              <th
                className="header-sort-button"
                onClick={() => handleSort("detailsTypeOfResponse")}
              >
                Form Type
              </th>
              <th
                className="header-sort-button"
                onClick={() => handleSort("detailsAssessor")}
              >
                Assessor
              </th>
              <th
                className="header-sort-button"
                onClick={() => handleSort("detailsAccessCode")}
              >
                Access Code
              </th>
              <th>Status</th>
              <th>Report</th>
              <th>Manage Response</th>
            </tr>
          </thead>
          <tbody>{currentTableData ? currentTableData : null}</tbody>
        </table>
        <Pagination
          className="pagination-bar"
          currentPage={currentPage}
          totalCount={responsesData.length}
          pageSize={pageSize}
          onPageChange={(page) => setCurrentPage(page)}
        />
      </div>
    );
  } else {
    return (
      <div className="responses-table">
        <h1>Manage Responses</h1>
        <CreateForm />
        <Spinner />;
      </div>
    );
  }
}

export default CASTResultsTable;
