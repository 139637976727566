// ========== imports ========== //

import React, { useRef, useState, useEffect, useCallback } from "react";

import { useParams, useLocation } from "react-router-dom";

import useAnalyticsPageViewSenderGA4 from "../../../../utils/useAnalyticsPageViewSenderGA4";

import Loggito from "../../../../utils/Loggito";
import withContext from "../../../../utils/withContext";

import { updateCastFormResponse } from "../../../../logic";

import { toast } from "react-toastify";

import { IoWarningOutline } from "react-icons/io5";

import { ServerError } from "errors";

import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";

import {
  schemaFormSectionB,
  schemaFormSectionBAutomated,
  validateSectionBAssessor,
  validateSectionBCandidate,
  validateSectionBAutomated,
} from "../../../../validators";

import {
  QuestionAssessmentContainer,
  QuestionMcq14Strongly,
  QuestionMcqList,
  QuestionShortText,
  QuestionTextBox,
  QuestionMcqListHorizontalB1,
} from "./FormComponents";

// ========== Page ========== //

function SectionBAutomated({ environment, onApproveSection, existingValues }) {
  // ========== Hook consts ========== //
  const [formDataBeingSent, setFormDataBeingSent] = useState(true);

  const [errorsAssessor, setErrorsAssessor] = useState({});
  const [errorsCandidate, setErrorsCandidate] = useState({});

  const form = useRef();
  const location = useLocation();

  const gaPageViewSender = useAnalyticsPageViewSenderGA4();
  useEffect(() => {
    gaPageViewSender("CAST Form Dev");
  }, []);

  let formSchema;

  if (environment === "assessor") formSchema = schemaFormSectionB;
  else if (environment === "candidate")
    formSchema = schemaFormSectionBAutomated;

  const {
    register: registerB,
    handleSubmit: handleSubmitB,
    reset,
    formState: { errors },
    getValues: getValuesB,
    watch,
    setValue,
  } = useForm({
    resolver: joiResolver(formSchema),
    reValidateMode: "onChange",
  });

  // THIS WORKS - VALUES MUST BE DEALT WITH SEPARATELY FOR SETVALUE

  useEffect(() => {
    if (Object.entries(existingValues).length > 0) {
      for (const [key, value] of Object.entries(existingValues)) {
        /*  if (key[0] === "B" && value) {
          setValue(key, value.response, {});
          // TODO: investigate the benefit of these attributes
            // shouldValidate: true,
            // shouldDirty: true,
        } */
        if (key[0] === "B") {
          if (value.response) setValue(key, value.response, {});
          if (value.score) setValue(key + "_score", value.score, {});
          if (value.comment) setValue(key + "_comment", value.comment, {});
        }
      }
      setFormDataBeingSent(false);
    }
  }, [existingValues]);

  const [userText, setUserText] = useState("");

  const handleUserKeyPress = useCallback((event) => {
    const { key, keyCode } = event;

    if (keyCode === 32 || (keyCode >= 65 && keyCode <= 90)) {
      setUserText(key);
    }
  }, []);

  useEffect(() => {
    const delayFn = setTimeout(() => saveFormData(null), 3000);
    return () => clearTimeout(delayFn);
  }, [userText]);

  useEffect(() => {
    // advised to create a constant variable as the form.current is likely to change before cleanup
    const formReferenced = form.current;
    formReferenced.addEventListener("keydown", handleUserKeyPress);

    // cleanup on dismount component
    return () => {
      formReferenced.removeEventListener("keydown", handleUserKeyPress);
    };
  }, [handleUserKeyPress]);

  // END SUBMIT AFTER TYPING

  const params = useParams();
  let route = params.route;

  const saveFormData = async (approved) => {
    // This part changes depending on the candidate or ssessor environment
    const questionNumbersArray = [
      "B1a",
      "B1b",
      "B1c",
      "B1d",
      "B1e",
      "B2",
      "B3",
      "B4",
      "B5",
      "B6",
      "B7",
      "B8",
      "B9",
      "B10",
      "B11",
      "B12",
    ];

    const values = getValuesB();

    let groupedValuesObject = {};
    questionNumbersArray.forEach((questionNumber) => {
      const scoreKey = questionNumber + "_score";
      const commentKey = questionNumber + "_comment";
      groupedValuesObject = {
        ...groupedValuesObject,
        [questionNumber]: {
          response: values[questionNumber],
          score: values[scoreKey],
          comment: values[commentKey],
        },
      };
    });

    console.log(values);

    if (approved === "approved")
      groupedValuesObject.detailsFormSectionApproved = "sectionB";

    let endpoint;

    if (location.pathname.slice(1).slice(0, 5) === "admin") {
      endpoint = "assessor";
    } else if (
      location.pathname.slice(1).slice(0, 5) === "admin" &&
      environment === "candidate"
    ) {
      endpoint = "candidateassessor";
    } else endpoint = "candidate";

    setFormDataBeingSent(true);
    try {
      updateCastFormResponse(
        sessionStorage,
        route,
        groupedValuesObject,
        endpoint,
        function (error) {
          if (error) {
            if (error instanceof ServerError) {
              toast.error(error.message);
              logger.error(error.message);
            } else {
              toast.warn(error.message);
              logger.warn(error.message);
            }
            return;
          }
          toast.success("Your answers have been saved");
        }
      );
    } catch (error) {
      logger.warn(error.message);
      toast.warn(error.message);
    }

    setFormDataBeingSent(false);
  };

  // THIS SEPARATE VALIDATION HANDLES THE FINAL REVIEW TO CLOSE THE SECTION AND MOVE ONTO THE NEXT

  const handleValidateSectionAssessor = () => {
    const formValues = getValuesB();
    const errors = validateSectionBAssessor(formValues);
    if (errors) {
      setErrorsAssessor(errors);
      return;
    } else {
      // HERE THE DATA SHOULD BE SAVED ONE FINAL TIME AND THEN THE FOLLWING STEPS, CLOSING THE SECTION AND MOVING ONTO THE FOLLOWING SECTION...
      setErrorsAssessor({});
      saveFormData(null);
      alert("Form Review OK and final data submitted");
      if (environment === "candidate") onApproveSection("sectionB");
      return;
    }
  };

  const handleValidateSectionCandidate = () => {
    const formValues = getValuesB();
    const errors = validateSectionBAutomated(formValues);
    if (errors) {
      setErrorsCandidate(errors);
      return;
    } else {
      // HERE THE DATA SHOULD BE SAVED ONE FINAL TIME AND THEN THE FOLLWING STEPS, CLOSING THE SECTION AND MOVING ONTO THE FOLLOWING SECTION...
      setErrorsCandidate({});
      saveFormData("approved");
      alert("Form Review OK and final data submitted");
      onApproveSection("sectionB");
      return;
    }
  };

  // ========== other consts ========== //

  const logger = new Loggito("Form section B");

  // ========== useEffects ========== //

  // ========== jsx ========== //

  return (
    <form
      ref={form}
      // onSubmit={handleSubmitB((data) => saveFormData())}
      onSubmit={(event) => {
        event.preventDefault();
        handleSubmitB((data) => saveFormData());
      }}
      className="cast-form"
      noValidate={true}
    >
      {(Object.keys(errorsAssessor).length !== 0 &&
        errorsAssessor.constructor === Object) ||
      (Object.keys(errorsCandidate).length !== 0 &&
        errorsCandidate.constructor === Object) ? (
        <div className="error-block">
          <IoWarningOutline className="icon" />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <p className="p--m">
            There are questions that require your attention
          </p>
        </div>
      ) : null}
      <div className="cast-form__title-block">
        <h2 className="h2">Section B</h2>
        <p className="p--m">
          Through the next questions, you will be asked a bit about your life
          and the challenges you have faced.
          <br />
          &nbsp;
        </p>
      </div>
      <QuestionMcqListHorizontalB1
        onSubmit={handleSubmitB((data) => saveFormData())}
        question={"B1a"}
        questionText={
          "How many different jobs have you had since you started full time work? (A change of job within the same organisation counts as one. Being self-employed also counts as one.)"
        }
        register={registerB}
        qErrors={errors.B1a}
        qErrorsAssessor={errorsAssessor.B1a}
        qErrorsCandidate={errorsCandidate.B1a}
      />
      {environment === "assessor" ? (
        <QuestionAssessmentContainer
          onSubmit={handleSubmitB((data) => saveFormData())}
          question={"B1a"}
          watch={watch}
          scoreType={"Automatically scored"}
          scoreExplanation={""}
          register={registerB}
          qErrorsAssessor={{
            score: errorsAssessor.B1a_score,
            comment: errorsAssessor.B1a_comment,
          }}
        />
      ) : null}
      <QuestionShortText
        question={"B1b"}
        questionText={
          "Have you ever been promoted? What is the highest level of position you have held?"
        }
        register={registerB}
        qErrors={errors.B1b}
        qErrorsAssessor={errorsAssessor.B1b}
        qErrorsCandidate={errorsCandidate.B1b}
      />
      {environment === "assessor" ? (
        <QuestionAssessmentContainer
          onSubmit={handleSubmitB((data) => saveFormData())}
          question={"B1b"}
          watch={watch}
          scoreType={"Automatically scored"}
          scoreExplanation={""}
          register={registerB}
          qErrorsAssessor={{
            score: errorsAssessor.B1b_score,
            comment: errorsAssessor.B1b_comment,
          }}
        />
      ) : null}
      <QuestionShortText
        question={"B1c"}
        questionText={
          "What is the shortest you have ever been in one job and what is the longest you have ever been in one job?"
        }
        register={registerB}
        qErrors={errors.B1c}
        qErrorsAssessor={errorsAssessor.B1c}
        qErrorsCandidate={errorsCandidate.B1c}
      />
      {environment === "assessor" ? (
        <QuestionAssessmentContainer
          onSubmit={handleSubmitB((data) => saveFormData())}
          question={"B1c"}
          watch={watch}
          scoreType={"Automatically scored"}
          scoreExplanation={""}
          register={registerB}
          qErrorsAssessor={{
            score: errorsAssessor.B1c_score,
            comment: errorsAssessor.B1c_comment,
          }}
        />
      ) : null}
      <QuestionShortText
        question={"B1d"}
        questionText={
          "How many years have you been in full-time employment (If you have been absent due to maternity leave or long-term sickness please state how long)? Do not include jobs that you did to support training, or getting a qualification, for example."
        }
        register={registerB}
        qErrors={errors.B1d}
        qErrorsAssessor={errorsAssessor.B1d}
        qErrorsCandidate={errorsCandidate.B1d}
      />
      {environment === "assessor" ? (
        <QuestionAssessmentContainer
          onSubmit={handleSubmitB((data) => saveFormData())}
          question={"B1d"}
          watch={watch}
          scoreType={"Automatically scored"}
          scoreExplanation={""}
          register={registerB}
          qErrorsAssessor={{
            score: errorsAssessor.B1d_score,
            comment: errorsAssessor.B1d_comment,
          }}
        />
      ) : null}
      <QuestionTextBox
        question={"B1e"}
        questionText={
          "If you have stayed at the same job for your entire career, please explain the reason."
        }
        register={registerB}
        qErrors={errors.B1e}
        qErrorsAssessor={errorsAssessor.B1e}
        qErrorsCandidate={errorsCandidate.B1e}
      />
      {environment === "assessor" ? (
        <QuestionAssessmentContainer
          onSubmit={handleSubmitB((data) => saveFormData())}
          question={"B1e"}
          watch={watch}
          scoreType={"Automatically scored"}
          scoreExplanation={""}
          register={registerB}
          qErrorsAssessor={{
            score: errorsAssessor.B1e_score,
            comment: errorsAssessor.B1e_comment,
          }}
        />
      ) : null}
      <QuestionShortText
        question={"B2"}
        questionText={[
          "At any time in your life have you ever had any hobbies or activities that you have pursued for more than 2 years (outside of the regular school /work hours)? This can be any kind of pursuit, e.g. sports, music, clubs, volunteer activities, or hobbies.",
          "If so what and for how long?",
          "Did you progress your ability in these pursuits? Please explain if you took on a leadership or a co-ordinating role in either of these 2 interests?",
        ]}
        register={registerB}
        qErrors={errors.B2}
        qErrorsAssessor={errorsAssessor.B2}
        qErrorsCandidate={errorsCandidate.B2}
      />
      {environment === "assessor" ? (
        <QuestionAssessmentContainer
          onSubmit={handleSubmitB((data) => saveFormData())}
          question={"B2"}
          watch={watch}
          scoreType={"Automatically scored"}
          scoreExplanation={""}
          register={registerB}
          qErrorsAssessor={{
            score: errorsAssessor.B2_score,
            comment: errorsAssessor.B2_comment,
          }}
        />
      ) : null}
      <QuestionMcqList
        onSubmit={handleSubmitB((data) => saveFormData())}
        question={"B3"}
        questionText={
          "How would you describe yourself concerning routines at home and work? Please choose the answer that most fits you."
        }
        register={registerB}
        qErrors={errors.B3}
        qErrorsAssessor={errorsAssessor.B3}
        qErrorsCandidate={errorsCandidate.B3}
        labels={[
          "I tend to go with how I feel rather than follow a routine",
          "I prioritize what is important rather than have regular routines",
          "I have some routines but don’t always follow them daily",
          "I have a clear routine at home and work but adapt when necessary",
        ]}
      />
      {environment === "assessor" ? (
        <QuestionAssessmentContainer
          onSubmit={handleSubmitB((data) => saveFormData())}
          question={"B3"}
          watch={watch}
          scoreType={"Automatically scored"}
          scoreExplanation={""}
          register={registerB}
          qErrorsAssessor={{
            score: errorsAssessor.B3_score,
            comment: errorsAssessor.B3_comment,
          }}
        />
      ) : null}
      <QuestionMcqList
        onSubmit={handleSubmitB((data) => saveFormData())}
        question={"B4"}
        questionText={
          "This question is about the qualities of determination, perseverance, and purpose and whether they can be learned or developed through conscious effort. Please choose the answer that most fits you."
        }
        register={registerB}
        qErrors={errors.B4}
        qErrorsAssessor={errorsAssessor.B4}
        qErrorsCandidate={errorsCandidate.B4}
        labels={[
          "These qualities are a fixed part of our character and can’t be developed",
          "These qualities tend to either in you or not and can’t be developed very much",
          "Sometimes these qualities can be developed by working hard on them",
          "With effort, a plan, and the support of a coach/mentor/senior professional, these qualities can usually be developed",
        ]}
      />
      {environment === "assessor" ? (
        <QuestionAssessmentContainer
          onSubmit={handleSubmitB((data) => saveFormData())}
          question={"B4"}
          watch={watch}
          scoreType={"Automatically scored"}
          scoreExplanation={""}
          register={registerB}
          qErrorsAssessor={{
            score: errorsAssessor.B4_score,
            comment: errorsAssessor.B4_comment,
          }}
        />
      ) : null}
      <QuestionMcq14Strongly
        onSubmit={handleSubmitB((data) => saveFormData())}
        question={"B5"}
        questionText={
          <>
            Please score the following statement from 0-3 according to how
            strongly you agree with it.
            <br />
            <br />I have shown a lot of resilience and determination in my life
            <br />
            &nbsp;
          </>
        }
        register={registerB}
        qErrors={errors.B5}
        qErrorsAssessor={errorsAssessor.B5}
        qErrorsCandidate={errorsCandidate.B5}
      />
      {environment === "assessor" ? (
        <QuestionAssessmentContainer
          onSubmit={handleSubmitB((data) => saveFormData())}
          question={"B5"}
          watch={watch}
          scoreType={"Automatically scored"}
          scoreExplanation={""}
          register={registerB}
          qErrorsAssessor={{
            score: errorsAssessor.B5_score,
            comment: errorsAssessor.B5_comment,
          }}
        />
      ) : null}
      <QuestionMcqList
        onSubmit={handleSubmitB((data) => saveFormData())}
        question={"B6"}
        questionText={
          "Which of the following sets of characteristics is closest to how people who know you well might describe you?"
        }
        register={registerB}
        qErrors={errors.B6}
        qErrorsAssessor={errorsAssessor.B6}
        qErrorsCandidate={errorsCandidate.B6}
        labels={[
          "Helpful, Caring, Good fun, Lively",
          "Compassionate, Easy Going, Respectful, Kind",
          "Kind, Humorous, Hopeful, Determined",
          "Purposeful, Caring, Adaptable, Dependable",
        ]}
      />
      {environment === "assessor" ? (
        <QuestionAssessmentContainer
          onSubmit={handleSubmitB((data) => saveFormData())}
          question={"B6"}
          watch={watch}
          scoreType={"Automatically scored"}
          scoreExplanation={""}
          register={registerB}
          qErrorsAssessor={{
            score: errorsAssessor.B6_score,
            comment: errorsAssessor.B6_comment,
          }}
        />
      ) : null}
      <QuestionMcqList
        onSubmit={handleSubmitB((data) => saveFormData())}
        question={"B7"}
        questionText={
          "What do you think of competition, in a general sense? Please choose the answer that most fits you."
        }
        register={registerB}
        qErrors={errors.B7}
        qErrorsAssessor={errorsAssessor.B7}
        qErrorsCandidate={errorsCandidate.B7}
        labels={[
          "Competition helps people boost their self-esteem",
          "Competition is unhelpful in a professional workplace",
          "Competition might be ok in sports, but we must be careful in how we use it at work",
          "Competition is good when it helps people to challenge themselves and develop their abilities",
        ]}
      />
      {environment === "assessor" ? (
        <QuestionAssessmentContainer
          onSubmit={handleSubmitB((data) => saveFormData())}
          question={"B7"}
          watch={watch}
          scoreType={"Automatically scored"}
          scoreExplanation={""}
          register={registerB}
          qErrorsAssessor={{
            score: errorsAssessor.B7_score,
            comment: errorsAssessor.B7_comment,
          }}
        />
      ) : null}
      <QuestionMcqList
        onSubmit={handleSubmitB((data) => saveFormData())}
        question={"B8"}
        questionText={
          "Please choose the set you think is most helpful to succeed at something."
        }
        register={registerB}
        qErrors={errors.B8}
        qErrorsAssessor={errorsAssessor.B8}
        qErrorsCandidate={errorsCandidate.B8}
        labels={[
          "Having the fortune to be born with a talent, enjoyment, and getting some lucky breaks",
          "Being in the right place at the right time and taking the opportunity",
          "Finding a passion and working relentlessly on it",
          "Knowing what you want, hard work, practice, not giving up, and using support",
        ]}
      />
      {environment === "assessor" ? (
        <QuestionAssessmentContainer
          onSubmit={handleSubmitB((data) => saveFormData())}
          question={"B8"}
          watch={watch}
          scoreType={"Automatically scored"}
          scoreExplanation={""}
          register={registerB}
          qErrorsAssessor={{
            score: errorsAssessor.B8_score,
            comment: errorsAssessor.B8_comment,
          }}
        />
      ) : null}
      <QuestionMcqList
        onSubmit={handleSubmitB((data) => saveFormData())}
        question={"B9"}
        questionText={
          "How would you define a genius and how do you think a person becomes a genius? Please choose the answer that most fits your view."
        }
        register={registerB}
        qErrors={errors.B9}
        qErrorsAssessor={errorsAssessor.B9}
        qErrorsCandidate={errorsCandidate.B9}
        labels={[
          "A genius is someone with an extraordinary gift that they are born with and who develops their natural talent",
          "A genius is someone with outstanding ability and who has progressed because they see the world differently",
          "A genius is someone who accomplishes outstanding achievements and who focuses on what they enjoy doing",
          "A genius is someone with outstanding ability that is gained from many years of focused work and learning from others",
        ]}
      />
      {environment === "assessor" ? (
        <QuestionAssessmentContainer
          onSubmit={handleSubmitB((data) => saveFormData())}
          question={"B9"}
          watch={watch}
          scoreType={"Automatically scored"}
          scoreExplanation={""}
          register={registerB}
          qErrorsAssessor={{
            score: errorsAssessor.B9_score,
            comment: errorsAssessor.B9_comment,
          }}
        />
      ) : null}
      <QuestionMcqList
        onSubmit={handleSubmitB((data) => saveFormData())}
        question={"B10"}
        questionText={
          "Can you think of a big disappointment in your life? If so, how did you respond to it? Please choose the answer that most fits you"
        }
        register={registerB}
        qErrors={errors.B10}
        qErrorsAssessor={errorsAssessor.B10}
        qErrorsCandidate={errorsCandidate.B10}
        labels={[
          "I had a big disappointment, and I think it is best to push it aside and move on",
          "I try not to let disappointments bother me too much and I work out my strategy rather than talk about it",
          "I had a big disappointment and recovered well by using the support and working out what to do",
          "Sometimes, time and patience are necessary and by working with others a big disappointment can be a point of growth",
        ]}
      />
      {environment === "assessor" ? (
        <QuestionAssessmentContainer
          onSubmit={handleSubmitB((data) => saveFormData())}
          question={"B10"}
          watch={watch}
          scoreType={"Automatically scored"}
          scoreExplanation={""}
          register={registerB}
          qErrorsAssessor={{
            score: errorsAssessor.B10_score,
            comment: errorsAssessor.B10_comment,
          }}
        />
      ) : null}
      <QuestionMcqList
        onSubmit={handleSubmitB((data) => saveFormData())}
        question={"B11"}
        questionText={
          "This question is about hope. What does hope mean to you? Please choose the answer that most fits you."
        }
        register={registerB}
        qErrors={errors.B11}
        qErrorsAssessor={errorsAssessor.B11}
        qErrorsCandidate={errorsCandidate.B11}
        labels={[
          "Hope means wishing that things will get better",
          "Hope means keeping the belief that things will get better",
          "Hope means not giving up when things are difficult",
          "Hope means having an optimistic outlook of creating a better future",
        ]}
      />
      {environment === "assessor" ? (
        <QuestionAssessmentContainer
          onSubmit={handleSubmitB((data) => saveFormData())}
          question={"B11"}
          watch={watch}
          scoreType={"Automatically scored"}
          scoreExplanation={""}
          register={registerB}
          qErrorsAssessor={{
            score: errorsAssessor.B11_score,
            comment: errorsAssessor.B11_comment,
          }}
        />
      ) : null}
      <QuestionMcqList
        onSubmit={handleSubmitB((data) => saveFormData())}
        question={"B12"}
        questionText={
          "Can you imagine what it would be like to feel hopeless? If so and you began to feel like that, what would you do?	 Please choose the answer that most fits you."
        }
        register={registerB}
        qErrors={errors.B12}
        qErrorsAssessor={errorsAssessor.B12}
        qErrorsCandidate={errorsCandidate.B12}
        labels={[
          "I have never felt hopeless and am not sure what I would do",
          "I can imagine how awful it must be to feel hopeless; I think I would try to keep going and see if it gets better",
          "I find it difficult to imagine how awful it would be to feel hopeless, but I think I would seek help",
          "I have felt hopeless and found ways of working through it by using others for support",
        ]}
      />
      {environment === "assessor" ? (
        <QuestionAssessmentContainer
          onSubmit={handleSubmitB((data) => saveFormData())}
          question={"B12"}
          watch={watch}
          scoreType={"Automatically scored"}
          scoreExplanation={""}
          register={registerB}
          qErrorsAssessor={{
            score: errorsAssessor.B12_score,
            comment: errorsAssessor.B12_comment,
          }}
        />
      ) : null}

      {formDataBeingSent ? (
        <button type="button" className="cta cta--accent--clicked">
          Review
        </button>
      ) : null}
      {environment === "assessor" && !formDataBeingSent ? (
        <button
          type="button"
          className="cta cta--accent"
          onClick={handleValidateSectionAssessor}
        >
          Review
        </button>
      ) : null}
      {environment === "candidate" && !formDataBeingSent ? (
        <button
          type="button"
          className="cta cta--accent"
          onClick={handleValidateSectionCandidate}
        >
          Review
        </button>
      ) : null}
    </form>
  );
}

export default withContext(SectionBAutomated);
