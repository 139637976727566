// ========== Imports ========== //

import "./AdminMainPage.css";
import { useState, useEffect } from "react";
import {
  Routes,
  Route,
  useNavigate,
  useLocation,
  Navigate,
} from "react-router-dom";
import Loggito from "../utils/Loggito";
import withContext from "../utils/withContext";
import { toast } from "react-toastify";
import { FormAccessPage } from ".";
import CandidateViewPage from "../Components/AdminComponents/CASTResults/CandidateViewPage";
import { retrieveUser } from "../logic";
import { ServerError } from "errors";

// ========== Page ========== //

function CandidateMainPage({ handleLogoutClick }) {
  // ========== Hook consts ========== //

  const [name, setName] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  // ========== other consts ========== //

  const logger = new Loggito("CandidateMainPage");

  // ========== useEffects ========== //

  // ========== Function to retrieve the user from the db and set the name ========== //

  /*   useEffect(() => {
    logger.info("componentDidMount");

    if (sessionStorage.token) {
      try {
        retrieveUser(sessionStorage.token, (error, user) => {
          if (error) {
            if (error instanceof ServerError) {
              toast.error(error.message);
              logger.error(error.message);
            } else {
              toast.warn(error.message);
              logger.warn(error.message);
            }
            handleLogoutClick();
            return;
          }

          setName(user.name);

          logger.debug("setName", user.name);
        });
      } catch (error) {
        logger.warn(error.message);
        toast.warn(error.message);
      }
    }
  }, []); */

  // ========== Function to navigate between pages ========== //

  // I think the . here is incorrect
  const handleReturn = () => {
    navigate("./");
  };

  const handleNavigationToForm = (route) => {
    navigate(`/candidate/access/candidate/${route}`);
    logger.debug("navigate to form");
  };

  const handleNavigationToRegister = () => {
    navigate("register");

    logger.debug("navigate to register");
  };

  const handleNavigateTo = (location) => {
    navigate(`/`);
    navigate(location);
  };

  // ========== Functions ========== //

  const handleUpdateUserDetails = () => {
    handleLogoutClick();
  };

  // ========== jsx ========== //

  return (
    <div className="">
      <main className="">
        <Routes>
          <Route
            path="/access"
            element={
              <FormAccessPage
                handleNavigationToRegister={handleNavigationToRegister}
                handleNavigationToForm={handleNavigationToForm}
              />
            }
          />
          <Route
            path="/access/:environment/:route"
            element={
              !sessionStorage.candidate_token ? (
                <Navigate to={`/candidate/access`} />
              ) : (
                <CandidateViewPage />
              )
            }
          />
        </Routes>
      </main>
      <footer className=""></footer>
    </div>
  );
}

export default withContext(CandidateMainPage);
