// ========== imports ========== //

import React, { useRef, useState, useEffect, useCallback } from "react";

import { useParams, useLocation } from "react-router-dom";

import useAnalyticsPageViewSenderGA4 from "../../../../utils/useAnalyticsPageViewSenderGA4";

import Loggito from "../../../../utils/Loggito";
import withContext from "../../../../utils/withContext";

import { updateCastFormResponse } from "../../../../logic";

import { toast } from "react-toastify";

import { IoWarningOutline } from "react-icons/io5";

import { ServerError } from "errors";

import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";

import {
  schemaFormSectionA,
  schemaFormSectionAAutomated,
  validateSectionAAssessor,
  validateSectionACandidate,
  validateSectionAAutomated,
} from "../../../../validators";

import {
  QuestionAssessmentContainer,
  QuestionMcqList,
  QuestionShortText,
  QuestionTextBox,
} from "./FormComponents";

// ========== Page ========== //

function SectionAAutomated({ environment, onApproveSection, existingValues }) {
  // ========== Hook consts ========== //
  const [formDataBeingSent, setFormDataBeingSent] = useState(true);

  const [errorsAssessor, setErrorsAssessor] = useState({});
  const [errorsCandidate, setErrorsCandidate] = useState({});

  const form = useRef();
  const location = useLocation();

  const gaPageViewSender = useAnalyticsPageViewSenderGA4();

  useEffect(() => {
    gaPageViewSender("CAST Form Dev");
  }, []);

  useEffect(() => {
    console.log("state:");
    console.log(errorsAssessor);
  }, [errorsAssessor]);

  let formSchema;

  if (environment === "assessor") formSchema = schemaFormSectionA;
  else if (environment === "candidate")
    formSchema = schemaFormSectionAAutomated;

  const {
    register: registerA,
    handleSubmit: handleSubmitA,
    reset,
    formState: { errors },
    getValues: getValuesA,
    watch,
    setValue,
  } = useForm({
    resolver: joiResolver(formSchema),
    reValidateMode: "onChange",
  });

  // THIS WORKS - VALUES MUST BE DEALT WITH SEPARATELY FOR SETVALUE

  useEffect(() => {
    if (Object.entries(existingValues).length > 0) {
      for (const [key, value] of Object.entries(existingValues)) {
        // TODO: investigate the benefit of these attributes
        // shouldValidate: true,
        // shouldDirty: true,
        if (key[0] === "A") {
          if (value.response) setValue(key, value.response, {});
          if (value.score) setValue(key + "_score", value.score, {});
          if (value.comment) setValue(key + "_comment", value.comment, {});
          // setValue(key, value.response, {});
        }
      }
      setFormDataBeingSent(false);
    }
  }, [existingValues]);

  // START SUBMIT AFTER TYPING - THIS SUBMITS THE USER'S RESPONSE AFTER THEY STOP TYPING

  const [userText, setUserText] = useState("");

  const handleUserKeyPress = useCallback((event) => {
    const { key, keyCode } = event;

    if (keyCode === 32 || (keyCode >= 65 && keyCode <= 90)) {
      setUserText(key);
    }
  }, []);

  useEffect(() => {
    const delayFn = setTimeout(() => saveFormData(null), 3000);
    return () => clearTimeout(delayFn);
  }, [userText]);

  useEffect(() => {
    // advised to create a constant variable as the form.current is likely to change before cleanup
    const formReferenced = form.current;
    formReferenced.addEventListener("keydown", handleUserKeyPress);

    // cleanup on dismount component
    return () => {
      formReferenced.removeEventListener("keydown", handleUserKeyPress);
    };
  }, [handleUserKeyPress]);

  // END SUBMIT AFTER TYPING

  const params = useParams();
  let route = params.route;

  const saveFormData = async (approved) => {
    // This part changes depending on the candidate or ssessor environment
    const questionNumbersArray = ["A1a", "A1b", "A2", "A3", "A4", "A5", "A6"];

    const values = getValuesA();

    let groupedValuesObject = {};

    questionNumbersArray.forEach((questionNumber) => {
      const scoreKey = questionNumber + "_score";
      const commentKey = questionNumber + "_comment";
      groupedValuesObject = {
        ...groupedValuesObject,
        [questionNumber]: {
          response: values[questionNumber],
          score: values[scoreKey],
          comment: values[commentKey],
        },
      };
    });

    if (approved === "approved")
      groupedValuesObject.detailsFormSectionApproved = "sectionA";

    console.log(groupedValuesObject);

    let endpoint;

    if (location.pathname.slice(1).slice(0, 5) === "admin") {
      endpoint = "assessor";
    } else if (
      location.pathname.slice(1).slice(0, 5) === "admin" &&
      environment === "candidate"
    ) {
      endpoint = "candidateassessor";
    } else endpoint = "candidate";

    setFormDataBeingSent(true);
    try {
      updateCastFormResponse(
        sessionStorage,
        route,
        groupedValuesObject,
        endpoint,
        function (error) {
          if (error) {
            if (error instanceof ServerError) {
              toast.error(error.message);
              logger.error(error.message);
            } else {
              toast.warn(error.message);
              logger.warn(error.message);
            }
            return;
          }
          toast.success("Your answers have been saved");
        }
      );
    } catch (error) {
      logger.warn(error.message);
      toast.warn(error.message);
    }

    setFormDataBeingSent(false);
  };

  // THIS SEPARATE VALIDATION HANDLES THE FINAL REVIEW TO CLOSE THE SECTION AND MOVE ONTO THE NEXT

  const handleValidateSectionAssessor = () => {
    const formValues = getValuesA();
    const errors = validateSectionAAssessor(formValues);
    if (errors) {
      console.log("errors:");
      console.log(errors);
      setErrorsAssessor(errors);
      return;
    } else {
      // HERE THE DATA SHOULD BE SAVED ONE FINAL TIME AND THEN THE FOLLWING STEPS, CLOSING THE SECTION AND MOVING ONTO THE FOLLOWING SECTION...
      setErrorsAssessor({});
      saveFormData(null);
      alert("Form Review OK and final data submitted");
      if (environment === "candidate") onApproveSection("sectionA");
      return;
    }
  };

  const handleValidateSectionCandidate = () => {
    const formValues = getValuesA();
    const errors = validateSectionAAutomated(formValues);
    if (errors) {
      setErrorsCandidate(errors);
      return;
    } else {
      // HERE THE DATA SHOULD BE SAVED ONE FINAL TIME AND THEN THE FOLLWING STEPS, CLOSING THE SECTION AND MOVING ONTO THE FOLLOWING SECTION...
      setErrorsCandidate({});
      saveFormData("approved");
      alert("Form Review OK and final data submitted");
      onApproveSection("sectionA");
      return;
    }
  };

  // ========== other consts ========== //

  const logger = new Loggito("Form section A");

  // ========== useEffects ========== //

  // ========== jsx ========== //

  return (
    <form
      ref={form}
      /*  onSubmit={(data) => {
      }} */

      // THIS WORKS BUT THE FORM IS SUBMITTED ON PAGE LOAD
      // onSubmit={handleSubmitA((data) => saveFormData())}
      // THIS STOPS THE SAVEFORMDATA() FUNCTION BEING CALLED
      onSubmit={(event) => {
        event.preventDefault();
        handleSubmitA((data) => saveFormData());
      }}
      className="cast-form"
      noValidate={true}
    >
      {(Object.keys(errorsAssessor).length !== 0 &&
        errorsAssessor.constructor === Object) ||
      (Object.keys(errorsCandidate).length !== 0 &&
        errorsCandidate.constructor === Object) ? (
        <div className="error-block">
          <IoWarningOutline className="icon" />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <p className="p--m">
            There are questions that require your attention
          </p>
        </div>
      ) : null}
      <div className="cast-form__title-block">
        <h2 className="h2">Section A</h2>
        <p className="p--m">
          In this section, you will be asked a series of questions about
          yourself.
          <br />
          &nbsp;
        </p>
      </div>
      <QuestionMcqList
        onSubmit={handleSubmitA((data) => saveFormData())}
        question={"A1a"}
        questionText={"Please choose the statement that most fits you"}
        register={registerA}
        qErrors={errors.A1a}
        qErrorsAssessor={errorsAssessor.A1a}
        qErrorsCandidate={errorsCandidate.A1a}
        labels={[
          "I view my work as just a necessity of life - it pays the bills",
          "I view my job mainly as a way of progressing to other jobs",
          "I enjoy making a difference, but I am not sure I am in the right job",
          "My work is one of the most important things in my life",
        ]}
      />
      {environment === "assessor" ? (
        <QuestionAssessmentContainer
          onSubmit={handleSubmitA((data) => saveFormData())}
          question={"A1a"}
          watch={watch}
          scoreType={"Automatically scored"}
          scoreExplanation={""}
          register={registerA}
          qErrorsAssessor={{
            score: errorsAssessor.A1a_score,
            comment: errorsAssessor.A1a_comment,
          }}
        />
      ) : null}
      <QuestionMcqList
        onSubmit={handleSubmitA((data) => saveFormData())}
        question={"A1b"}
        questionText={"Please choose the statement that most fits you"}
        register={registerA}
        qErrors={errors.A1b}
        qErrorsAssessor={errorsAssessor.A1b}
        qErrorsCandidate={errorsCandidate.A1b}
        labels={[
          "I don’t enjoy my work very much",
          "I am ambitious to earn a better salary",
          "I enjoy my work and being part of a team",
          "I find my work to be meaningful and satisfying",
        ]}
      />
      {environment === "assessor" ? (
        <QuestionAssessmentContainer
          onSubmit={handleSubmitA((data) => saveFormData())}
          question={"A1b"}
          watch={watch}
          scoreType={"Automatically scored"}
          scoreExplanation={""}
          register={registerA}
          qErrorsAssessor={{
            score: errorsAssessor.A1b_score,
            comment: errorsAssessor.A1b_comment,
          }}
        />
      ) : null}
      <QuestionMcqList
        onSubmit={handleSubmitA((data) => saveFormData())}
        question={"A2"}
        questionText={
          "What motivates you in your work? Please choose the statement that most fits you"
        }
        register={registerA}
        qErrors={errors.A2}
        qErrorsAssessor={errorsAssessor.A2}
        qErrorsCandidate={errorsCandidate.A2}
        labels={[
          "Doing well and being praised",
          "Advancing my career and earning a good salary",
          "Doing a good job and helping others",
          "Doing something that makes a difference and developing myself",
        ]}
      />
      {environment === "assessor" ? (
        <QuestionAssessmentContainer
          onSubmit={handleSubmitA((data) => saveFormData())}
          question={"A2"}
          watch={watch}
          scoreType={"Automatically scored"}
          scoreExplanation={""}
          register={registerA}
          qErrorsAssessor={{
            score: errorsAssessor.A2_score,
            comment: errorsAssessor.A2_comment,
          }}
        />
      ) : null}
      <QuestionMcqList
        onSubmit={handleSubmitA((data) => saveFormData())}
        question={"A3"}
        questionText={
          "Think of a role model or mentor in your life (friend, family, colleague, etc.). Please choose the set of qualities that you most admire."
        }
        register={registerA}
        qErrors={errors.A3}
        qErrorsAssessor={errorsAssessor.A3}
        qErrorsCandidate={errorsCandidate.A3}
        labels={[
          "a) clever and talented b) good fun and friendly c) respectful and honest",
          "a) thoughtful and honest b) caring and compassionate c) good fun and friendly",
          "a) caring and compassionate b) humourous and kind c) hard-working and purposeful",
          "a) hard-working and purposeful b) determined and optimistic c) helpful and empathic",
        ]}
      />
      {environment === "assessor" ? (
        <QuestionAssessmentContainer
          onSubmit={handleSubmitA((data) => saveFormData())}
          question={"A3"}
          watch={watch}
          scoreType={"Automatically scored"}
          scoreExplanation={""}
          register={registerA}
          qErrorsAssessor={{
            score: errorsAssessor.A3_score,
            comment: errorsAssessor.A3_comment,
          }}
        />
      ) : null}
      <QuestionMcqList
        onSubmit={handleSubmitA((data) => saveFormData())}
        question={"A4"}
        questionText={
          "Which of these statements most reflects what you want to be doing in 10 years?"
        }
        register={registerA}
        qErrors={errors.A4}
        qErrorsAssessor={errorsAssessor.A4}
        qErrorsCandidate={errorsCandidate.A4}
        labels={[
          "I will do my job to the best of my ability for as long as I am working",
          "I would like to make progress in my work but I’m not sure where that will take me",
          "I plan to keep working hard and hopefully I will take on more responsibility",
          "I expect to make significant progress, and I have a clear plan in place",
        ]}
      />
      {environment === "assessor" ? (
        <QuestionAssessmentContainer
          onSubmit={handleSubmitA((data) => saveFormData())}
          question={"A4"}
          watch={watch}
          scoreType={"Automatically scored"}
          scoreExplanation={""}
          register={registerA}
          qErrorsAssessor={{
            score: errorsAssessor.A4_score,
            comment: errorsAssessor.A4_comment,
          }}
        />
      ) : null}
      <QuestionMcqList
        onSubmit={handleSubmitA((data) => saveFormData())}
        question={"A5"}
        questionText={
          "Think of a few words to describe the core values that you live by. Please choose the set of qualities that most fit you."
        }
        register={registerA}
        qErrors={errors.A5}
        qErrorsAssessor={errorsAssessor.A5}
        qErrorsCandidate={errorsCandidate.A5}
        labels={[
          "Perseverance, kindness, accountability, and optimistic",
          "Care, empathy, honesty, respect",
          "Respect, compassion, determination, hope",
          "Humour, compassion, kindness, respect",
        ]}
      />
      {environment === "assessor" ? (
        <QuestionAssessmentContainer
          onSubmit={handleSubmitA((data) => saveFormData())}
          question={"A5"}
          watch={watch}
          scoreType={"Automatically scored"}
          scoreExplanation={""}
          register={registerA}
          qErrorsAssessor={{
            score: errorsAssessor.A5_score,
            comment: errorsAssessor.A5_comment,
          }}
        />
      ) : null}
      <QuestionMcqList
        onSubmit={handleSubmitA((data) => saveFormData())}
        question={"A6"}
        questionText={
          "Which of these statements most reflects what you would like as your life legacy?"
        }
        register={registerA}
        qErrors={errors.A6}
        qErrorsAssessor={errorsAssessor.A6}
        qErrorsCandidate={errorsCandidate.A6}
        labels={[
          "That people will remember me as an honest person with a good sense of humour",
          "That I treated everyone well and had an impact that reached many people",
          "That people could trust me and come to me for help",
          "That I was a hard-working and caring person at home and work",
        ]}
      />
      {environment === "assessor" ? (
        <QuestionAssessmentContainer
          onSubmit={handleSubmitA((data) => saveFormData())}
          question={"A6"}
          watch={watch}
          scoreType={"Automatically scored"}
          scoreExplanation={""}
          register={registerA}
          qErrorsAssessor={{
            score: errorsAssessor.A6_score,
            comment: errorsAssessor.A6_comment,
          }}
        />
      ) : null}

      {formDataBeingSent ? (
        <button type="button" className="cta cta--accent--clicked">
          Review
        </button>
      ) : null}
      {environment === "assessor" && !formDataBeingSent ? (
        <button
          type="button"
          className="cta cta--accent"
          onClick={handleValidateSectionAssessor}
        >
          Review
        </button>
      ) : null}
      {environment === "candidate" && !formDataBeingSent ? (
        <button
          type="button"
          className="cta cta--accent"
          onClick={handleValidateSectionCandidate}
        >
          Review
        </button>
      ) : null}
    </form>
  );
}

export default withContext(SectionAAutomated);
