// ========== imports ========== //

// ========== Page ========== //
function QuestionMcq14Strongly({
  question,
  questionText,
  register,
  qErrors,
  qErrorsAssessor,
  qErrorsCandidate,
  onSubmit,
}) {
  // ========== Hook consts ========== //

  return (
    /* ASSESSOR BLOCK - OUTSIDE Q CONTAINER */
    <div className="cast-form__question-container" id={`${question}_link`}>
      <div className="cast-form__question cast-form__question--radio">
        <p className="p--m cast-form__question__qtext">
          {<b>{question}</b>}: {questionText}
        </p>
        <div className="radio-options" id={question}>
          <p className="p--sm">strongly disagree</p>
          <div>
            <label htmlFor={question + "_0"} className="p--sm">
              0
            </label>
            <input
              type="radio"
              id={question + "_0"}
              name={question}
              value={0}
              {...register(question, {
                onChange: (e) => {
                  onSubmit();
                },
              })}
            />
          </div>
          <div>
            <label htmlFor={question + "_1"} className="p--sm">
              1
            </label>
            <input
              type="radio"
              id={question + "_1"}
              name={question}
              value={1}
              {...register(question, {
                onChange: (e) => {
                  onSubmit();
                },
              })}
            />
          </div>
          <div>
            <label htmlFor={question + "_2"} className="p--sm">
              2
            </label>
            <input
              type="radio"
              id={question + "_2"}
              name={question}
              value={2}
              {...register(question, {
                onChange: (e) => {
                  onSubmit();
                },
              })}
            />
          </div>
          <div>
            <label htmlFor={question + "_3"} className="p--sm">
              3
            </label>
            <input
              type="radio"
              id={question + "_3"}
              name={question}
              value={3}
              {...register(question, {
                onChange: (e) => {
                  onSubmit();
                },
              })}
            />
          </div>
          <p className="p--sm">strongly agree</p>
        </div>
        {/* ERROR BLOCK - WITHIN Q CONTAINER */}
        <div className="cast-form__error-message">
          {qErrors && (
            <p className="input__error-message">{qErrors?.message}</p>
          )}
        </div>
        <div className="cast-form__error-message">
          {qErrorsCandidate && (
            <p className="input__error-message">{qErrorsCandidate}</p>
          )}
        </div>
        <div className="cast-form__error-message">
          {qErrorsAssessor && (
            <p className="input__error-message">{qErrorsAssessor}</p>
          )}
        </div>
      </div>
    </div>
  );
}

export default QuestionMcq14Strongly;
